import React from 'react'

export default function Modal(props) {
  const {modalContent, modalId, header} = props
  return (
    <div id={modalId} className='modal modal-fixed-footer'>
      <div>
        {typeof header !== 'header' && header}
        <div className='modal-content'>{modalContent}</div>
      </div>
      <div className='modal-footer'>{props.children}</div>
    </div>
  )
}
