import React, {useState, useContext, useEffect} from 'react'
import AuthContext from '../../context/auth/AuthContext'
import M from 'materialize-css/dist/js/materialize.min.js'
import {SimpleContainer} from '../layout/Containers'

export const Register = (props) => {
  const authContext = useContext(AuthContext)

  const {register, token} = authContext

  useEffect(() => {
    if (token) {
      props.history.push('/send-verification-email')
    }
    // eslint-disable-next-line
  }, [token, props.history])

  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    password_conf: '',
    registration_key: '',
    pw_match: true,
  })

  const {name, email, password, password_conf} = user

  let pw_check = true

  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    })
  }

  const onPasswordConfChange = (e) => {
    e.target.value === user.password ? (pw_check = true) : (pw_check = false)
    setUser({
      ...user,
      password_conf: e.target.value,
      pw_match: pw_check,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (password !== password_conf) {
      M.toast({html: "Passwords don't match!"})
    } else {
      register({
        name,
        email,
        password,
      })
    }
  }

  return (
    <SimpleContainer>
      <div className='text-center text-3xl font-bold'>
        Sign up for <div className='text-orange-400'>Grocerylist.me</div>
      </div>
      <div className=''>
        <form onSubmit={onSubmit}>
          <div className='m-10'>
            <div className='input-field'>
              <label htmlFor='name'>
                <span className='pl-3'>Name</span>
              </label>
              {/* TODO do not allow empty name */}
              <input
                type='text'
                name='name'
                id='name'
                value={name}
                onChange={onChange}
                required
              />
            </div>
          </div>
          <div className='m-10'>
            <div className='input-field'>
              <label htmlFor='email'>
                <span className='pl-3'>Email</span>
              </label>{' '}
              <input
                type='text'
                name='email'
                id='email'
                className='validate'
                value={email}
                onChange={onChange}
                required
              />
            </div>
          </div>
          <div className='m-10'>
            <div className='input-field'>
              <label htmlFor='password'>
                <span className='pl-3'>Password</span>
              </label>
              <input
                type='password'
                name='password'
                id='password'
                value={password}
                onChange={onChange}
                className='validate'
                required
                minLength='6'
              />
            </div>
          </div>
          <div className='m-10'>
            <div className='input-field'>
              <label htmlFor='password_conf'>
                <span className='pl-3'>Confirm password</span>
              </label>
              <input
                id='password_conf'
                type='password'
                name='password_conf'
                value={password_conf}
                onChange={onPasswordConfChange}
                className={`${user.pw_match ? 'valid' : 'invalid'}`}
                required
                minLength='6'
              />
            </div>
          </div>
          <div className='flex justify-center'>
            <button
              type='submit'
              name='action'
              className='w-1/2 bg-emerald-500 rounded-full p-5 text-white font-extrabold'
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </SimpleContainer>
  )
}
