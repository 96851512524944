import React, {useEffect} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import 'materialize-css/dist/css/materialize.min.css'
import M from 'materialize-css/dist/js/materialize.min.js'
import './App.css'
import ListState from './context/list/ListState'
import StoreState from './context/store/StoreState'
import PlaceState from './context/place/PlaceState'
import AuthState from './context/auth/AuthState'
import UserState from './context/user/UserState'
import {NewList} from './components/newList/NewList'
import {Home} from './components/Home'
import Landing from './components/ui/Landing'
import {ShopList} from './components/shopList/ShopList'
import {Register} from './components/auth/Register'
import {Login} from './components/auth/Login'
import {AppNavBar} from './components/layout/AppNavBar'
import {Footer} from './components/layout/Footer'
import {setAuthToken} from './utils/setAuthToken'
import ItemsPage from './components/items/ItemsPage'
import StoresPage from './components/stores/StoresPage'
import HomesPage from './components/homes/HomesPage'
import Authorizer from './components/auth/Authorizer'
import AboutPage from './components/ui/AboutPage'
import TestPage from './components/ui/TestPage'
import VerifyPage from './components/ui/VerifyPage'
import VerificationSent from './components/ui/VerificationSent'
import {PrivateRoute} from './components/routing/PrivateRoute'

if (localStorage.token) {
  setAuthToken(localStorage.token)
}

const App = () => {
  useEffect(() => {
    // Initializes MaterializeJS
    M.AutoInit()
    // const elem = document.querySelector('#menu-slider')
    // M.Sidenav.init(elem, {
    //   edge: 'right',
    // })
  })

  return (
    <UserState>
      <PlaceState>
        <AuthState>
          <StoreState>
            <ListState>
              <Router>
                <>
                  {/* <Authorizer> */}
                    <div className='min-h-screen flex flex-col justify-between'>
                      <AppNavBar />
                      <Switch>
                        <Route exact path='/' component={Landing} />
                        <Route exact path='/register' component={Register} />
                        <Route exact path='/login' component={Login} />
                        <PrivateRoute exact path='/home' component={Home} />
                        <PrivateRoute
                          exact
                          path='/newlist'
                          component={NewList}
                        />
                        <PrivateRoute
                          exact
                          path='/shoplist'
                          component={ShopList}
                        />
                        <PrivateRoute
                          exact
                          path='/items'
                          component={ItemsPage}
                        />
                        <PrivateRoute
                          exact
                          path='/stores'
                          component={StoresPage}
                        />
                        <PrivateRoute
                          exact
                          path='/homes'
                          component={HomesPage}
                        />
                        <Route exact path='/about' component={AboutPage} />
                        <Route
                          path='/send-verification-email'
                          component={VerificationSent}
                        />
                        <Route path='/verify/:token' component={VerifyPage} />
                        <PrivateRoute
                          exact
                          path='/hellothisisatestkthx'
                          component={TestPage}
                        />
                        {/* <Route
                                exact
                                path='/locations'
                                component={LocationsList}
                                />
                                <Route path='/homes/:id' component={HomeDetail} />
                                <Route
                                path='/locations/:id'
                                component={LocationDetail}
                              /> */}
                      </Switch>
                      <Footer />
                    </div>
                  {/* </Authorizer> */}
                </>
              </Router>
            </ListState>
          </StoreState>
        </AuthState>
      </PlaceState>
    </UserState>
  )
}

export default App
