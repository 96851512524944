import React from 'react'

const CategoryListCollection = ({
  collectionArray,
  action,
  elem,
  secondaryContent,
  secondaryContentAction,
  secondaryContentText,
}) => {
  let clickAttr

  if (action) {
    clickAttr = action
  } else {
    clickAttr = () => console.log("I don't click!")
  }

  return (
    <>
      {collectionArray.length !== 0 ? (
        collectionArray.map((m, i) => (
          <a
            onClick={() => clickAttr(m)}
            className='flex h-20 border-2 text-gray-700'
            key={i}
          >
            <div className='h-16 w-16 my-auto mx-3 bg-gray-300'></div>
            <div className='flex flex-col mt-2 gap-2 '>
              <div className='ml-1 text-2xl'>{m.name}</div>
              <div className='flex' onClick={() => secondaryContentAction(m)}>
                <div className='flex items-center mr-1'>{secondaryContent}</div>
                {/* <div className='ml-1 inline-block'>:</div> */}
                <div className='flex items-end text-xs text-gray-600'>
                  {secondaryContentText}
                </div>
              </div>
            </div>
          </a>
        ))
      ) : (
        <div className='flex text-gray-600 border-y-2 py-4'>
          <div className='w-1/3 text-right'></div>
          <div className='w-1/3 text-center'>
            <div className='text-6xl'>😕</div>
            <div className='text-2xl'>Nothing here yet...</div>
          </div>
          <div className='w-1/3 text-right pr-2'>
            <div className='text-4xl'>👆</div>
            <div className='text-xs'>(Why not add some?)</div>
          </div>
        </div>
      )}
    </>
  )
}

export default CategoryListCollection
