import React, {useContext} from 'react'
import PlaceContext from '../../context/place/PlaceContext'
import ModalActionAndTrigger from '../ui/ModalActionAndTrigger'
import {useHistory} from 'react-router-dom'

const HomeDetailHeader = (props) => {
  const {home, changeAction, deleteClick} = props
  const placeContext = useContext(PlaceContext)

  const {clearCurrent} = placeContext

  const onChange = (e) => {
    e.preventDefault()
    changeAction(e.target.value)
  }

  const backClick = () => {
    clearCurrent({target: 'currentHome'})
  }

  const trigger = <i className='material-icons'>delete</i>
  const modalContent = 'Deleting cannot be undone.  Continue?'

  return (
    <>
      <div className='row'>
        <div className='valign-wrapper detail-header center'>
          <div className='col s2 detail-header-icon'>
            <a onClick={backClick}>
              <i className='material-icons'>arrow_back</i>
            </a>
          </div>
          <div className='col s8 center'>
            <span className='detail-header-text'>{home.name}</span>
          </div>
          <div className='col s2 detail-header-icon'>
            <ModalActionAndTrigger
              modalId='deleteConfirmModal'
              modalContent={modalContent}
              action={deleteClick}
              trigger={trigger}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default HomeDetailHeader
