import React, {useContext, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import AuthContext from '../../context/auth/AuthContext'
import {DropdownContent} from './DropdownContent'
import M from 'materialize-css/dist/js/materialize.min.js'
import carrot from '../../img/icon-carrot-small.png'
import HelpNav from './HelpNav'

export const AppNavBar = () => {
  // TODO have sidenav close after selection made? onClick?
  useEffect(() => {
    const elem = document.querySelector('#menu-slider')
    M.Sidenav.init(elem, {
      edge: 'right',
    })
  })

  const authContext = useContext(AuthContext)

  const {isAuthenticated, logout, loading} = authContext

  let authenticated
  isAuthenticated ? (authenticated = true) : (authenticated = false)

  const homePage = authenticated ? '/home' : '/'

  return (
    <>
      <nav className='bg-orange-600 merriweather flex justify-center'>
        <div className='flex max-w-screen-xl flex-grow justify-between px-3 sm:w-10'>
          <div className='flex items-center'>
            <Link to={homePage} className='flex align-center h-9'>
              <img className='mx-2 max-w-1' alt='Grocerylist.me logo' src={carrot}></img>
              <div className='mt-0.5 font-bold text-2xl'>Grocerylist.me</div>
              <div className='bg-emerald-500 rounded-full flex py-1 px-2 my-auto mx-3 text-sm'>
                <div className='mx-auto'>Beta</div>
              </div>
            </Link>
          </div>
          <div className='flex mr-auto items-center'>
            <HelpNav />
          </div>

          <a
            href='#'
            className='md:hidden right sidenav-trigger'
            data-target='menu-slider'
          >
            <i className='material-icons'>menu</i>
          </a>
          <ul className='right hidden md:block'>
            <DropdownContent authenticated={isAuthenticated} />
          </ul>
        </div>
      </nav>
      <div id='menu-slider' className='sidenav'>
        <DropdownContent authenticated={isAuthenticated} />
      </div>
    </>
  )
}
