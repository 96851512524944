export const SET_CURRENT = 'SET_CURRENT'
export const CLEAR_CURRENT = 'CLEAR_CURRENT'
export const SET_ALERT = 'SET_ALERT'
export const CLEAR_ALERT = 'CLEAR_ALERT'
export const PUSH_CURRENT = 'PUSH_CURRENT'
export const POP_CURRENT = 'POP_CURRENT'

// auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGOUT = 'LOGOUT'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'
export const SEND_VERIFICATION_EMAIL_SUCCESS = 'SEND_VERIFICATION_EMAIL_SUCCESS'
export const SEND_VERIFICATION_EMAIL_FAILURE = 'SEND_VERIFICATION_EMAIL_FAILURE'
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS'


export const GET_STORES = 'GET_STORES'
export const GET_STORE = 'GET_STORE'
export const ADD_STORE = 'ADD_STORE'
export const DELETE_STORE = 'DELETE_STORE'
export const UPDATE_STORE = 'UPDATE_STORE'
export const STORE_ERROR = 'STORE_ERROR'
export const GET_STORE_ITEMS = 'GET_STORE_ITEMS'

export const GET_LISTS = 'GET_LISTS'
export const GET_LIST = 'GET_LIST'
export const ADD_LIST = 'ADD_LIST'
export const DELETE_LIST = 'DELETE_LIST'
export const UPDATE_LIST = 'UPDATE_LIST'
export const LIST_ERROR = 'LIST_ERROR'

export const GET_ITEMS = 'GET_ITEMS'
export const GET_ITEM = 'GET_ITEM'
export const ADD_ITEM = 'ADD_ITEM'
export const DELETE_ITEM = 'DELETE_ITEM'
export const UPDATE_ITEM = 'UPDATE_ITEM'
export const ITEM_ERROR = 'ITEM_ERROR'

export const GET_HOMES = 'GET_HOMES'
export const GET_HOME = 'GET_HOME'
export const ADD_HOME = 'ADD_HOME'
export const DELETE_HOME = 'DELETE_HOME'
export const UPDATE_HOME = 'UPDATE_HOME'
export const HOME_ERROR = 'HOME_ERROR'

export const GET_LISTITEMS = 'GET_LISTITEMS'
export const GET_LISTITEM = 'GET_LISTITEM'
export const ADD_LISTITEMS = 'ADD_LISTITEMS'
export const DELETE_LISTITEM = 'DELETE_LISTITEM'
export const UPDATE_LISTITEM = 'UPDATE_LISTITEM'
export const LISTITEM_ERROR = 'LISTITEM_ERROR'

export const GET_LOCATIONS = 'GET_LOCATIONS'
export const GET_LOCATION = 'GET_LOCATION'
export const ADD_LOCATION = 'ADD_LOCATION'
export const DELETE_LOCATION = 'DELETE_LOCATION'
export const UPDATE_LOCATION = 'UPDATE_LOCATION'
export const LOCATION_ERROR = 'LOCATION_ERROR'

export const GET_USERS = 'GET_USERS'
export const GET_USER = 'GET_USER'
export const ADD_USER = 'ADD_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const USER_ERROR = 'USER_ERROR'
export const VERIFY_USER = 'VERIFY_USER'

export const GET_PLACES = 'GET_PLACES'
export const GET_PLACE = 'GET_PLACE'
export const ADD_PLACE = 'ADD_PLACE'
export const DELETE_PLACE = 'DELETE_PLACE'
export const UPDATE_PLACE = 'UPDATE_PLACE'
export const PLACE_ERROR = 'PLACE_ERROR'

export const GET_HOME_ITEMS = 'GET_HOME_ITEMS'
