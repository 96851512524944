import React, {useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {HelpIcon} from '../../img/images'
import TailwindModal from './TailwindModal'
import {Default, Home} from './help/HelpText'

const HelpNav = () => {
  const [display, setDisplay] = useState('invisible')
  const [helpText, setHelpText] = useState(<Default />)
  const path = useLocation().pathname

  const onClickHelp = () => {
    console.log(path, helpText)
    display === 'invisible' ? setDisplay('visible') : setDisplay('invisible')
    switch (path) {
      case '/home':
        setHelpText(<Home />)
        break
      default:
        setHelpText(<Default />)
    }
  }

  return (
    <Link to={path} onClick={() => onClickHelp()} className='mr-auto'>
      <HelpIcon height='10' width='10' color='fill-orange-300' />
      <TailwindModal display={display}>{helpText}</TailwindModal>
    </Link>
  )
}

export default HelpNav
