import React from 'react'
import Laptop from '../layout/Laptop'

const AboutPage = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col s0 m1 l2'></div>
        <div className='col s12 m10 l8'>
          <div className='card horizontal'>
            <div className='card-image'>
              <Laptop />
            </div>
            <div className='card-stacked'>
              <div className='card-content'>
                <span>This app was made with ❤️</span>
                <div> by Matt.</div>
              </div>
              <div className='card-action'>
                <a href='https://github.com/matthew-webber'>My Github</a>
              </div>
            </div>
          </div>
        </div>
        <div className='col s0 m1 l2'></div>
      </div>
    </div>
  )
}

export default AboutPage
