import React, {useContext, useState} from 'react'
import ListContext from '../../context/list/ListContext'

export const NewListItem = ({item}) => {
  const listContext = useContext(ListContext)

  const {pushItemCurrentList, popItemCurrentList} = listContext

  const [isChecked, setisChecked] = useState(false)

  const {name} = item

  const onChange = (e) => {
    setisChecked(!isChecked)
    !isChecked ? pushItemCurrentList(item) : popItemCurrentList(item)
  }

  const highlight = (e) => {
    e.target.style.background = 'rgb(230,230,230)'
  }

  const unhighlight = (e) => {
    e.target.style.background = ''
  }

  return (
    // TODO pull out style
    <li className='checkbox-buffer'>
      <label>
        <input type='checkbox' onChange={onChange} checked={isChecked} />
        <span
          className='full-width-checkbox'
          onMouseEnter={highlight}
          onMouseLeave={unhighlight}
          style={{color: 'black'}}
        >
          {name}
        </span>
      </label>
    </li>
  )
}
