import {
  SET_CURRENT,
  CLEAR_CURRENT,
  GET_STORES,
  GET_STORE,
  GET_STORE_ITEMS,
  ADD_STORE,
  DELETE_STORE,
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case GET_STORE:
    case GET_STORES:
      return {
        ...state,
        stores: action.payload,
      }
    case GET_STORE_ITEMS:
      return {
        ...state,
        currentStoreItems: action.payload,
      }
    case ADD_STORE:
      return {
        ...state,
        stores: [...state.stores, action.payload],
      }
    case DELETE_STORE:
      return {
        ...state,
        stores: state.stores.filter(
          (store) => store._id !== action.payload._id
        ),
      }
    case SET_CURRENT:
      return {
        ...state,
        currentStore: action.payload,
      }
    case CLEAR_CURRENT:
      return {
        ...state,
        currentStore: null,
      }
    default:
      return state
  }
}
