import React, {useState, useContext} from 'react'
import StoreDetailHeader from './StoreDetailHeader'
import ModalActionAndTrigger from '../ui/ModalActionAndTrigger'
import RefContainerList1 from '../ui/RefContainerList1'
import RefContainerList2 from '../ui/RefContainerList2'
import {
  getDeepCopy,
  useFormHandler,
  createCheckBoxes,
  createTextInputs,
} from '../../hooks/Custom'
import TextInputModal from '../ui/TextInputModal'
import _ from 'lodash'
import StoreContext from '../../context/store/StoreContext'
import {goBackTrigger, goBackConfirm} from '../../hooks/Custom'
import {PencilIcon, PlusIcon} from '../../img/images'

// need to pass currentStore so that it will have a passed reference to 'stores' and will update that reference on submit
const StoreDetail = (props) => {
  const {storeUpdate, storeDelete, currentStore} = props

  const storeContext = useContext(StoreContext)

  const {clearCurrentStore} = storeContext

  // informs what items to render under a location + what boxes should
  // already be checked in the edit modal (listRef and comparisonArr)
  const getItemsInLocation = (store, location) => {
    return store.items.filter((item) =>
      item.locations
        .map((loc) => loc.name)
        .flat()
        .includes(location.name)
    )
  }

  // this stores the changes to-be-saved while the user is editing
  // this is what gets passed on submit
  const [thisStore, setThisStore] = useState({
    ...currentStore,
    name: currentStore.name,
    locations: currentStore.locations,
    items: currentStore.items, // all stores that current item is in
  })

  // this is what gets edited from the modal
  // necessary for checkbox management and UI updating
  const [thisStoreShadow, setThisStoreShadow] = useState({
    ...currentStore,
    name: currentStore.name,
    locations: getDeepCopy(currentStore.locations),
    items: getDeepCopy(currentStore.items),
  })

  console.log(
    'this store == currentStore',
    _.isEqual(thisStore, currentStore),
    thisStore,
    currentStore
  )

  const onChange = (e) => {
    // copy thisStoreShadow since it's not easy to update the state object
    // directly
    const itemName = e.target.name
    const locationName = e.target.getAttribute('checkBoxGroup') // TODO rename / add new attr 'parentContainer'?

    const locations = thisStoreShadow.locations
    const items = thisStoreShadow.items

    const matchingLocation = locations.find(
      (location) => location.name === locationName
    )
    const matchingItem = items.find((item) => item.name === itemName)

    matchingItem.locations = e.target.checked
      ? [...matchingItem.locations, matchingLocation]
      : matchingItem.locations.filter(
          (location) => location.name !== matchingLocation.name
        )
    setThisStoreShadow((prevState) => ({...prevState, items}))
  }

  const onChangeLocationName = (e) => {
    e.preventDefault()
    changeVal({[e.target.name]: e.target.value})
  }

  const addLocation = (location) => {
    // keep these 2 in sync for the modal onChange otherwise shadow
    // will overwrite changes after the OK modal button clicked
    setThisStore((prevState) => ({
      ...prevState,
      locations: [...prevState['locations'], location],
    }))
    setThisStoreShadow((prevState) => ({
      ...prevState,
      locations: [...prevState['locations'], location],
    }))
  }

  const [changeVal, submitVal, state] = useFormHandler(addLocation, {
    name: '',
  })

  const deleteLocation = (location) => {
    // remove location from main location storage
    const locations = thisStoreShadow.locations.filter(
      (loc) => loc.name !== location.name
    )
    // remove location from items which have it in their item.locations
    const items = thisStoreShadow.items.map((item) => {
      item.locations = item.locations.filter(
        (loc) => loc.name !== location.name
      )
      return item
    })
    // keep these 2 in sync for the modal onChange otherwise shadow
    // will overwrite changes after the OK modal button clicked
    setThisStoreShadow((prevState) => ({...prevState, locations, items}))
    setThisStore((prevState) => ({...prevState, locations, items}))
  }

  const modalOkItems = () => {
    // get a copy since thisStore shouldn't respond to the checkboxes
    // changing until OK selected
    const {name, locations, items} = getDeepCopy(thisStoreShadow)
    setThisStore((prevState) => ({...prevState, name, locations, items}))
  }

  const modalOkLocations = () => {
    submitVal(state)
    changeVal({name: ''})
  }

  const getLocationModalContent = () => {
    return createTextInputs({
      changeAction: onChangeLocationName,
      idStrings: ['name'],
      valueObjs: [state],
      metaOptions: {},
    })
  }

  // generate checkboxes based on location parent container
  const getItemCheckBoxes = (items, location) => {
    const itemNames = items.map((item) => item.name)
    return createCheckBoxes(
      thisStore.items,
      itemNames,
      onChange,
      location.name,
      ['full-width-checkbox', 'checkbox-buffer']
    )
  }

  const backButton = _.isEqual(thisStore, currentStore) ? (
    <a onClick={clearCurrentStore}>{goBackTrigger}</a>
  ) : (
    <ModalActionAndTrigger
      modalId='goBackConfirmModal'
      modalContent={goBackConfirm}
      action={clearCurrentStore}
      trigger={goBackTrigger}
    />
  )

  const unorganizedItems = thisStore.items.filter(
    (item) => item.locations.length === 0
  )

  const addLocationHeader = (
    <span className='center'>New {thisStore.name} Location</span>
  )

  const editLocationsHeader = (
    <span className='center'>Edit {thisStore.name} Locations</span>
  )

  const editIcon = <PencilIcon color='fill-gray-600' height='10' width='10' />
  const addIcon = <PlusIcon color='fill-gray-700' height='10' width='10' />

  return (
    <div className='container'>
      <StoreDetailHeader
        backButton={backButton}
        deleteClick={storeDelete}
        store={thisStore}
        changeAction={() => console.log('change action')}
      />
      <div className='row'>
        <div className='col s12'>
          <button
            className='green-button'
            onClick={() => storeUpdate({thisStore})}
          >
            <div className='center-text-vertical uppercase'>Save</div>
          </button>
        </div>
      </div>
      <RefContainerList2
        listRef={thisStore.locations}
        headerText='Locations'
        editButton={editIcon}
        insertButton={
          <TextInputModal
            modalId='newLocationModal1'
            modalContent={getLocationModalContent}
            action={modalOkLocations}
            trigger={addIcon}
            header={addLocationHeader}
          />
        }
        secondaryContentAction={deleteLocation}
      />
      {thisStore.locations.map((location, i) => {
        const locationItems = getItemsInLocation(thisStore, location)
        return (
          <RefContainerList1
            key={i}
            listRef={locationItems}
            headerText={location.name}
            editButton={
              <ModalActionAndTrigger
                modalId={`edit${location.name.replace(/ /g, '')}Modal`}
                action={modalOkItems}
                modalContent={getItemCheckBoxes(locationItems, location)}
                trigger={editIcon}
                header={editLocationsHeader}
              />
            }
          />
        )
      })}
      {unorganizedItems.length > 0 && (
        <RefContainerList1
          listRef={unorganizedItems}
          headerText='Unorganized'
          editButton={''}
        />
      )}
    </div>
  )
}

export default StoreDetail
