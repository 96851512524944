import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import AuthContext from '../context/auth/AuthContext'
import ListContext from '../context/list/ListContext'
import PlaceContext from '../context/place/PlaceContext'
import StoreContext from '../context/store/StoreContext'
import {SimpleContainer} from './layout/Containers'

export const Home = () => {
  const authContext = useContext(AuthContext)
  const listContext = useContext(ListContext)
  const storeContext = useContext(StoreContext)
  const placeContext = useContext(PlaceContext)

  const {loadUser} = authContext
  const {clearCurrentList, getLists} = listContext
  const {clearCurrentStore, getStores} = storeContext
  const {getPlaces, clearCurrent, setCurrent} = placeContext

  useEffect(() => {
    const getUserPlace = async () => {
      const myFirstPlace = await getPlaces()
      if (myFirstPlace) {
        // TODO better way to implement if no user logged in
        setCurrent({data: myFirstPlace[0], target: 'currentPlace'})
      }
    }
    const userLoad = async () => {
      await loadUser()
    }

    clearCurrentStore()
    clearCurrentList()
    clearCurrent({target: 'currentPlace'})
    getLists()
    getStores()
    getUserPlace()
    userLoad()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <SimpleContainer>
        <div className='text-center text-3xl p-10 font-bold text-slate-800'>
          Let's get <div className='text-orange-400'>started.</div>
        </div>
        <div className='flex flex-col gap-10'>
          <Link to='/newlist' className=''>
            <div className='flex justify-center items-center w-full h-24 rounded-full border-orange-600 border-2 shadow-md shadow-orange-400/50'>
              <div className='text-slate-700 text-xl'>New List</div>
            </div>
          </Link>
          <Link to='/shoplist' className=''>
            <div className='flex justify-center items-center w-full h-24 rounded-full border-emerald-600 border-2 shadow-md shadow-emerald-400/50'>
              <div className='text-slate-700 text-xl'>Shop Lists</div>
            </div>
          </Link>
        </div>
      </SimpleContainer>
    </>
  )
}
