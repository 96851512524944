import React, {useContext, useEffect} from 'react'
import ListContext from '../../context/list/ListContext'
import StoreContext from '../../context/store/StoreContext'
import AuthContext from '../../context/auth/AuthContext'
import {StoresListElement} from './StoresListElement'
import {Spinner} from '../layout/Spinner'
import {Link} from 'react-router-dom'

export const StorePickPage = ({toggle}) => {
  const storeContext = useContext(StoreContext)
  const listContext = useContext(ListContext)
  const authContext = useContext(AuthContext)

  const {stores, getStores, setCurrentStore} = storeContext
  const {setCurrentList} = listContext
  const {user} = authContext

  let loading

  useEffect(() => {
    loading = true
    getStores()
    loading = false
    // eslint-disable-next-line
  }, [])

  console.log(stores, 'stores')

  const storeClick = (s) => {
    loading = true
    setCurrentStore(s)
    setCurrentList({
      items: [],
      store: s,
      user: user,
    })
    // toggle()
    loading = false // TODO implement better loading
  }

  // TODO add "but you can add one here!"
  const storeStatus =
    stores !== null && stores.length !== 0 ? (
      <>
        <div className='mt-10 flex flex-col gap-10'>
          {stores.map((store) => (
            <StoresListElement
              key={store._id}
              storeClick={(s) => storeClick(s)}
              store={store}
            />
          ))}
        </div>
      </>
    ) : (
      <div className='container'>
        <div className='row center-align'>
          <h4>
            No stores yet... <Link to='/stores'>Create one!</Link>
          </h4>
        </div>
      </div>
    )

  return (
    <div>
      {stores !== null && !loading ? (
        <div className='text-center text-3xl p-10 font-bold text-slate-800'>
          Where are we <div className='text-orange-400'>shopping?</div>
          {storeStatus}
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
