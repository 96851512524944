import React, {useContext, useEffect, useState} from 'react'
import PlaceContext from '../../context/place/PlaceContext'
import M from 'materialize-css/dist/js/materialize.min.js'
import CategoryListPage from '../ui/CategoryListPage'
import ModalActionAndTrigger from '../ui/ModalActionAndTrigger'
import CategoryListCollection from '../ui/CategoryListCollection'
import {NameField} from '../ui/FieldNames'
import {createTextInputs, useFormHandler} from '../../hooks/Custom'
import TextInputModal from '../ui/TextInputModal'
import {CardContainer, ListContainer} from '../layout/Containers'
import {PlusIcon} from '../../img/images'

const HomesList = ({action}) => {
  const placeContext = useContext(PlaceContext)
  let {updatePlace, setCurrent, currentPlace} = placeContext

  const [changeVal, submitVal, state] = useFormHandler(updatePlace, {
    name: '',
  })

  useEffect(() => {
    const elem = document.querySelector('#newHomeModal1')
    M.Modal.init(elem, {
      onOpenEnd() {
        document.querySelector('#name').focus()
      },
      onCloseEnd() {
        document.querySelector('#name').value = ''
      },
    })
  }, [])

  const onChange = (e) => {
    e.preventDefault()
    changeVal({[e.target.name]: e.target.value})
  }

  // modal add item listener
  const onClickAddHome = async () => {
    const updatedPlace = {
      ...currentPlace,
      homes: [...currentPlace.homes, state],
    }
    submitVal(updatedPlace)
    setCurrent({
      data: updatedPlace,
      target: 'currentPlace',
    })
    changeVal({name: ''})
  }

  const getModalContent = () => {
    return createTextInputs({
      changeAction: onChange,
      idStrings: ['name'],
      valueObjs: [state],
      metaOptions: {},
    })
  }

  const newHomeHeader = (
    <div
      className='center'
      style={{
        lineHeight: '2',
        fontSize: '2em',
        marginTop: '2px',
      }}
    >
      New Home
    </div>
  )

  const insertTrigger = (
    <PlusIcon color='fill-gray-700' height='10' width='10' />
  )

  return (
    <>
      {currentPlace && (
        <>
          <CategoryListPage
            model={currentPlace.homes}
            headerString='Homes'
            insertButton={
              <TextInputModal
                modalId='newHomeModal1'
                modalContent={getModalContent}
                action={onClickAddHome}
                trigger={insertTrigger}
                triggerClassNames=''
                header={newHomeHeader}
              />
            }
            collection={
              <CategoryListCollection
                collectionArray={currentPlace.homes}
                action={action}
              />
            }
          />
        </>
      )}
    </>
  )
}

export default HomesList
