import {
  GET_USERS,
  GET_USER,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  USER_ERROR,
  VERIFY_USER,
} from '../types'

const userReducer = (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
      }
    case GET_USER:
      return {
        ...state,
        currentUser: action.payload,
      }
    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
      }
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      }
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
      }
    case USER_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case VERIFY_USER:
      return {
        ...state,
        // isAuthenticated: true,
      }
    default:
      return state
  }
}

export default userReducer
