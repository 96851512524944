import React, {useContext, useEffect} from 'react'
import {NewListHomesContainer} from './NewListHomesContainer'
import StoreContext from '../../context/store/StoreContext'
import {Spinner} from '../layout/Spinner'
import PlaceContext from '../../context/place/PlaceContext'
import SaveListModal from './SaveListModal'
import M from 'materialize-css'
import ListContext from '../../context/list/ListContext'
import {useHistory} from 'react-router-dom'

export const NewListPage = ({store}) => {
  const storeContext = useContext(StoreContext)
  const placeContext = useContext(PlaceContext)
  const listContext = useContext(ListContext)

  const {currentStore, clearCurrentStore} = storeContext
  const {currentPlace} = placeContext
  let {currentList, addList, clearCurrentList} = listContext

  const history = useHistory()

  useEffect(() => {
    const collapsible = document.querySelector('.collapsible.expandable')
    M.Collapsible.init(collapsible, {accordion: false})
    const saveModal = document.querySelector('#saveModal')
    M.Modal.init(saveModal)
    // eslint-disable-next-line
  }, [])

  const backClick = () => {
    clearCurrentStore()
  }

  const getUniqueHomes = (store, place) => {
    const homesArr = Array.from(
      new Set(
        store.items
          .map((storeItem) =>
            place.items.filter((item) => item.name == storeItem.name)
          )
          .flat()
          .map((item) => item.homes)
          .flat()
          .map((home) => home.name)
          .flat()
      )
    )
    return homesArr
  }

  // TODO figure out how to get the store.items which DON'T match a homeName
  // get the store.items which match the given homeName
  const getHomeItems = (store, place, homeName) => {
    const itemsAtStore = store.items.map((storeItem) =>
      place.items.filter((item) => item.name == storeItem.name)
    )
    const itemsArr = itemsAtStore.flat().filter((item) =>
      item.homes
        .map((home) => home.name)
        .flat()
        .includes(homeName)
    )
    return itemsArr
  }

  let homes = null
  if (homes === null) {
    homes = getUniqueHomes(store, currentPlace)
  }

  const onSave = async () => {
    addList(currentList)
    M.toast({
      html: 'List saved!',
    })
    clearCurrentList()
    history.goBack()
  }

  return (
    <>
      {!currentStore ? (
        <Spinner />
      ) : (
        <>
          {/* M.toast({html: "Passwords don't match!"}) */}
          <div onClick={() => onSave()} className='fixed-action-btn'>
            <a
              className='btn-floating btn-large green modal-trigger'
              href='#saveModal'
            >
              <i className='large material-icons'>check</i>
            </a>
          </div>
          {/* <SaveListModal /> */}
          <div className='row valign-wrapper'>
            <div className=' col s1' style={{height: '100%'}}>
              <a onClick={backClick}>
                <i className='material-icons'>arrow_back</i>
              </a>
            </div>
            <div className='col s3'></div>
            <div className='col s4'>
              <h4 className='center-align'>{currentStore.name}</h4>
            </div>
            <div className='col s2'></div>
            <div className='col s2'></div>
          </div>
          <div className='row'>
            <div className='col s12'>
              <ul className='collapsible expandable'>
                {homes !== null && (
                  <>
                    {homes.map((homeName, i) => (
                      <NewListHomesContainer
                        key={i}
                        homeName={homeName}
                        items={getHomeItems(
                          currentStore,
                          currentPlace,
                          homeName
                        )}
                      />
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        </>
      )}
    </>
  )
}
