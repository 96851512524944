import React, {useState, useContext, useEffect, useRef} from 'react'
import AuthContext from '../../context/auth/AuthContext'
import {SimpleContainer} from '../layout/Containers'
import {Spinner} from '../layout/Spinner'

export const Login = (props) => {
  const authContext = useContext(AuthContext)

  const {login, isAuthenticated, error, clearErrors} = authContext

  const [loading, setLoading] = useState(false)
  const [signInUi, setSignInUi] = useState({
    color: 'bg-emerald-500',
    text: 'Sign In',
  })

  const emailRef = useRef('')
  const passwordRef = useRef('')

  useEffect(() => {
    if (isAuthenticated) {
      clearErrors()
      setSignInUi({color: 'bg-emerald-400', text: 'Signing In...'})
      const successRedirect = setTimeout(() => {
        props.history.push('/home')
      }, 2000)
      return () => clearTimeout(successRedirect)
    }
    // eslint-disable-next-line
  }, [isAuthenticated, props.history])

  const onSubmit = async (e) => {
    e.preventDefault()
    clearErrors()
    await login({
      email: emailRef.current.value,
      password: passwordRef.current.value,
    })
  }

  return (
    <SimpleContainer>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {error && error.response.status === 400 && (
            <div className='flex justify-center'>
              <div className='flex justify-center w-full md:w-1/2 bg-red-400 mb-3 p-3 rounded-xl border-2 border-red-500'>
                <span className='text-gray-50 text-center'>
                  Incorrect email or password.
                </span>
              </div>
            </div>
          )}
          {error && error.response.status === 403 && (
            <div className='flex justify-center'>
              <div className='flex justify-center w-full md:w-1/2 bg-red-400 mb-3 p-3 rounded-xl border-2 border-red-500'>
                <span className='text-gray-50 text-center'>
                  Please check your inbox and verify your email or{' '}
                  <a
                    className='text-emerald-400'
                    href={`mailto:matt@grocerylist.me?subject=Resend%20verification%20email%20for%20${emailRef.current.value}&body=Please%20and%20thank%20you%21`}
                  >
                    request a new one
                  </a>
                  .
                </span>
              </div>
            </div>
          )}
          <div className='text-center text-3xl font-bold'>
            It's <span className='text-emerald-400'>shoppin' time.</span>
          </div>
          <form className='md:px-10' onSubmit={onSubmit}>
            <div className='my-10'>
              <div>
                <label htmlFor='email'>
                  <span className='pl-3'>Email</span>
                </label>
                <input
                  type='text'
                  name='email'
                  id='email'
                  className='validate'
                  autoComplete='on'
                  ref={emailRef}
                  required
                />
              </div>
            </div>
            <div className='my-10'>
              <div>
                <label htmlFor='password'>
                  <span className='pl-3'>Password</span>
                </label>
                <input
                  type='password'
                  name='password'
                  id='password'
                  autoComplete='on'
                  ref={passwordRef}
                  className='validate'
                  required
                />
              </div>
            </div>
            <div className='flex flex-col items-center'>
              <button
                type='submit'
                name='login'
                className={`w-1/2 ${signInUi.color} rounded-full p-5 text-white text-xl font-bold`}
              >
                {signInUi.text}
              </button>
            </div>
          </form>
        </div>
      )}
    </SimpleContainer>
  )
}
