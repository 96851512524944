import React, {useContext} from 'react'
import StoreContext from '../../context/store/StoreContext'
import ModalActionAndTrigger from '../ui/ModalActionAndTrigger'
import {deleteTrigger, deleteConfirm} from '../../hooks/Custom'

const StoreDetailHeader = (props) => {
  const {store, deleteClick, backButton} = props
  const storeContext = useContext(StoreContext)

  return (
    <>
      <div className='row'>
        <div className='valign-wrapper detail-header center'>
          <div className='col s2 detail-header-icon'>{backButton}</div>
          <div className='col s8 center'>
            <span className='detail-header-text'>{store.name}</span>
          </div>
          <div className='col s2 detail-header-icon'>
            <ModalActionAndTrigger
              modalId='deleteConfirmModal'
              modalContent={deleteConfirm}
              action={deleteClick}
              trigger={deleteTrigger}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default StoreDetailHeader
