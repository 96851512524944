import React from 'react'
import {NewListItem} from './NewListItem'

export const NewListHomesContainer = ({homeName, items}) => {
  return (
    <>
      <li>
        <div className='collapsible-header'>
          <i className='material-icons'>filter_drama</i>
          {homeName}
        </div>
        <div className='collapsible-body'>
          <ul>
            {items.map((item, i) => (
              <NewListItem key={i} item={item} />
            ))}
          </ul>
        </div>
      </li>
    </>
  )
}
