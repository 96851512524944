import React from 'react'

export const StoresListElement = ({store, storeClick}) => {
  return (
    <div
      className='flex justify-center items-center w-full h-24 rounded-full border-orange-600 border-2 shadow-md shadow-orange-400/50'
      onClick={() => storeClick(store)}
    >
      <a className='text-slate-700 text-xl'>{store.name}</a>
    </div>
  )
}
