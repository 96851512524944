import {
  GET_LISTS,
  SET_CURRENT,
  ADD_LIST,
  UPDATE_LIST,
  CLEAR_CURRENT,
  PUSH_CURRENT,
  POP_CURRENT,
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case GET_LISTS:
      return {
        ...state,
        lists: action.payload,
      }
    case ADD_LIST:
      return {
        ...state,
        lists: [...state.lists, action.payload],
      }
    case UPDATE_LIST:
      return {
        ...state,
        lists: state.lists.map((list) =>
          list._id === action.payload._id ? action.payload : list
        ),
      }
    case SET_CURRENT:
      return {
        ...state,
        currentList: action.payload,
      }
    case CLEAR_CURRENT:
      return {
        ...state,
        currentList: null,
      }
    case PUSH_CURRENT:
      return {
        ...state,
        currentList: {
          ...state.currentList,
          items: [...state.currentList.items, action.payload],
        },
      }
    case POP_CURRENT:
      return {
        ...state,
        currentList: {
          ...state.currentList,
          items: state.currentList.items.filter(
            (item) => item.name !== action.payload.name
          ),
        },
      }
    default:
      return state
  }
}
