import React from 'react'

const TailwindModal = ({display, ...props}) => {
  return (
    <div
      className={`${display} transition-all ease-in duration-100 fixed z-50 pt-10 left-0 top-0 w-full h-full bg-black/70`}
    >
      <div className='bg-slate-100 mx-auto my-10 w-4/5 sm:w-3/5 md:w-1/2 lg:w-2/5 xl:w-1/3 2xl:w-1/4 h-auto text-slate-600 rounded-xl shadow-xl shadow-emerald-400'>
        <div className='flex flex-col gap-4 leading-snug p-10'>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default TailwindModal
