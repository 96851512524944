import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  CLEAR_ERRORS,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SEND_VERIFICATION_EMAIL_FAILURE,
  VERIFICATION_SUCCESS,
} from '../types'

const authReducer = (state, action) => {
  switch (action.type) {
    case REGISTER_SUCCESS:
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        ...action.payload, // this puts the token in state
        loading: false,
      }
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        ...action.payload, // this puts the token in state
        isAuthenticated: true,
        loading: false,
      }
    case REGISTER_FAILURE:
    case LOGIN_FAILURE:
    case AUTH_ERROR:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        error: action.payload,
        user: null,
      }
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      }
    case SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        verificationEmailSent: true,
      }
    case SEND_VERIFICATION_EMAIL_FAILURE:
      return {
        ...state,
        loading: false,
        verificationEmailSent: false,
      }
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        isVerified: true,
      }
    default:
      return state
  }
}

export default authReducer
