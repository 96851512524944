import React, {useReducer} from 'react'
import UserContext from './UserContext'
import userReducer from './userReducer'
import {
  GET_USERS,
  GET_USER,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  USER_ERROR,
  VERIFY_USER,
} from '../types'
import axios from 'axios'

const UserState = (props) => {
  const initialState = {
    users: null,
    currentUser: null,
    error: null,
    isAuthenticated: false,
  }

  const [state, dispatch] = useReducer(userReducer, initialState)

  // Get users
  const getUsers = async () => {
    try {
      const res = await axios.get('/api/users')
      dispatch({type: GET_USERS, payload: res.data})
      return res.data
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err})
    }
  }

  // Get user
  const getUser = async (id) => {
    try {
      const res = await axios.get(`/api/users/${id}`)
      dispatch({type: GET_USER, payload: res.data})
      return res.data
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err})
    }
  }

  // Add user
  const addUser = async (user) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      const res = await axios.post('/api/users', user, config)
      dispatch({type: ADD_USER, payload: res.data})
      return res.data
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err})
    }
  }

  // Delete user
  const deleteUser = async (id) => {
    try {
      await axios.delete(`/api/users/${id}`)
      dispatch({type: DELETE_USER, payload: id})
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err})
    }
  }

  // Update user
  const updateUser = async (user) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      const res = await axios.put(`/api/users/${user._id}`, user, config)
      dispatch({type: UPDATE_USER, payload: res.data})
      return res.data
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err})
    }
  }

  const verifyUser = async (token) => {
    try {
      const res = await axios.get(`/api/users/verify/${token}`)
      dispatch({type: VERIFY_USER, payload: res.data})
      return res.data
    } catch (err) {
      dispatch({type: USER_ERROR, payload: err})
    }
  }

  return (
    <UserContext.Provider
      value={{
        users: state.users,
        currentUser: state.currentUser,
        error: state.error,
        getUsers,
        getUser,
        addUser,
        deleteUser,
        updateUser,
        verifyUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export default UserState
