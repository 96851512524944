import React, {useContext} from 'react'
import {Link} from 'react-router-dom'
import AuthContext from '../../context/auth/AuthContext'

export const DropdownContent = ({authenticated, hideOnResize}) => {
  const authContext = useContext(AuthContext)

  const {logout} = authContext

  const onClick = () => {
    logout()
  }

  return authenticated ? (
    <>
      <li className=''>
        <Link className='sidenav-close' to='/items'>
          Items
        </Link>
      </li>
      <li className=''>
        <Link className='sidenav-close' to='/shoplist'>
          Lists
        </Link>
      </li>
      <li>
        <Link className='sidenav-close' to='/homes'>
          Homes
        </Link>
      </li>
      <li className=''>
        <Link className='sidenav-close' to='/stores'>
          Stores
        </Link>
      </li>
      <li className='divider' tabIndex='-1'></li>
      <li className=''>
        <Link className='sidenav-close' to='/about'>
          About
        </Link>
      </li>
      <li className='divider' tabIndex='-1'></li>
      <li>
        <Link onClick={onClick} to=''>
          Sign Out
        </Link>
      </li>
    </>
  ) : (
    <>
      {/* HACK this still interfaces with materialize, hence why it is in the "dropdown" content even though these are buttons */}
      <li className='md:hidden'>
        <div className='flex flex-col p-4 gap-4 justify-center'>
          <Link
            className='text-center text-white bg-orange-400 hover:bg-orange-300 ease-in-out duration-300 p-2 rounded-full'
            to='/register'
          >
            <div className='sidenav-close'>Sign Up</div>
          </Link>
          <Link
            className='text-center pt-5 px-2 pb-2 -mb-12 border-t-2 border-gray-200'
            to='/login'
          >
            <div className='sidenav-close text-gray-500'>Sign In</div>
          </Link>
        </div>
      </li>
      <div className='hidden md:block'>
        <li>
          <Link className='px-10 text-center' to='/login'>
            Sign In
          </Link>
        </li>
        <li>
          <Link
            className='px-10 text-center text-white bg-orange-400'
            to='/register'
          >
            Sign Up
          </Link>
        </li>
      </div>
    </>
  )
}
