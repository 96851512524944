import React, {useReducer} from 'react'
import ListContext from './ListContext'
import listReducer from './listReducer'
import {
  ADD_LIST,
  DELETE_LIST,
  UPDATE_LIST,
  SET_CURRENT,
  CLEAR_CURRENT,
  GET_LISTS,
  LIST_ERROR,
  PUSH_CURRENT,
  POP_CURRENT,
} from '../types'
import axios from 'axios'

const ListState = (props) => {
  const initialState = {
    lists: [],
    currentList: null,
  }

  const [state, dispatch] = useReducer(listReducer, initialState)

  // Get list
  const getList = async (id) => {
    try {
      const res = await axios.get(`/api/lists/${id}`)

      return res.data
    } catch (err) {
      console.log(err, 'err')
    }
  }

  // Get lists
  const getLists = async () => {
    try {
      const res = await axios.get('/api/lists')
      dispatch({type: GET_LISTS, payload: res.data})
    } catch (err) {
      console.log(err, 'err')
    }
  }

  // Add list
  const addList = async (list) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await axios.post('/api/lists', list, config)
    dispatch({type: ADD_LIST, payload: res.data})
  }

  // Update list
  const updateList = async (data) => {
    try {
      console.log('sending list!', data)
      const res = await axios.put(`/api/lists/${data._id}`, data)
      console.log('response from updatelist', res.data)
      dispatch({type: UPDATE_LIST, payload: res.data})
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  // Delete list
  const deleteList = async (list) => {
    try {
      const res = await axios.delete(`/api/lists/${list._id}`)
      dispatch({type: DELETE_LIST, payload: res.data})
    } catch (err) {
      console.error(err)
    }
  }

  // Set current list
  const setCurrentList = (list) => {
    dispatch({type: SET_CURRENT, payload: list})
  }

  // Clear current list
  const clearCurrentList = () => {
    dispatch({type: CLEAR_CURRENT})
  }

  // Push item to currentList.items
  const pushItemCurrentList = (item) => {
    dispatch({type: PUSH_CURRENT, payload: item})
  }

  // Pop item from currentList.items
  const popItemCurrentList = (item) => {
    dispatch({type: POP_CURRENT, payload: item})
  }

  return (
    <ListContext.Provider
      value={{
        lists: state.lists,
        currentList: state.currentList,
        getList,
        getLists,
        addList,
        updateList,
        deleteList,
        setCurrentList,
        clearCurrentList,
        pushItemCurrentList,
        popItemCurrentList,
      }}
    >
      {props.children}
    </ListContext.Provider>
  )
}

export default ListState
