import React, {useContext, useEffect} from 'react'
import {Link} from 'react-router-dom'
import AuthContext from '../../context/auth/AuthContext'
import {DropdownContent} from './DropdownContent'
import M from 'materialize-css/dist/js/materialize.min.js'
import carrot from '../../img/icon-carrot-small.png'

export const Footer = () => {
  return (
    <>
      <nav className='h-20 py-5 bg-orange-600 merriweather'>
        <div className='flex items-center'>
          <div to='/' className='flex mx-auto h-9'>
            <img className='mx-2 max-w-1' src={carrot}></img>
          </div>
        </div>
      </nav>
    </>
  )
}
