import React from 'react'

const header = (text = 'Help Box') => {
  return (
    <>
      <div className='text-center tracking-tight uppercase font-extrabold text-2xl text-slate-800'>
        {text}
      </div>
      <div className=' border-b-2'></div>
    </>
  )
}

const subText = () => {
  <>
  <div className="">
    </div></>
}

const footer = (text = 'Click or tap to close') => {
  return (
    <>
      <div className=' border-b-2'></div>
      <div className='text-center tracking-tight text-lg text-slate-600'>
        {text}
      </div>
    </>
  )
}

export const Home = () => {
  return (
    <>
      {header('Home')}
      <div className=''>
        Whenever you sign in or tap the Grocerylist.me logo in the top-left
        corner, you will be brought to the home page.
      </div>
      <div className=''>
        <div className=''>
          The home page is the place where you get the most important work done:
        </div>
      </div>
      <div className='mt-2 text-center'>
        <div className='p-2'>
          Creating{` `}
          <span className='text-orange-400 tracking-wider px-2'>new lists</span>
        </div>
        <div className='p-2'>
          Shopping{` `}
          <span className='text-emerald-400 tracking-wider px-2'>
            existing lists
          </span>
        </div>
      </div>
      {footer()}
    </>
  )
}

export const Default = () => {
  return (
    <>
      {header()}
      <p className='font-extrabold'>Content for this page coming soon...</p>
      <p>
        In the future, helpful information for each page will be found here.
      </p>
      <p>I hope you find the feature useful once it's complete!</p>
      <p>~Matt</p>
    </>
  )
}
