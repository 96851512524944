import React, {useContext, useEffect, useState} from 'react'
import AuthContext from '../../context/auth/AuthContext'
import PlaceContext from '../../context/place/PlaceContext'
import StoreContext from '../../context/store/StoreContext'
import ItemDetail from './ItemDetail'
import ItemsList from './ItemsList'
import {CardContainer, ListContainer} from '../layout/Containers'

const ItemsPage = () => {
  const placeContext = useContext(PlaceContext)
  const storeContext = useContext(StoreContext)
  const authContext = useContext(AuthContext)

  const {loadUser} = authContext
  const {currentItem, getPlaces, setCurrent, currentPlace} = placeContext
  const {getStores} = storeContext

  const [loading, setLoading] = useState(true)

  useEffect(async () => {
    await loadUser()
    const myFirstPlace = await getPlaces()
    if (myFirstPlace) {
      // TODO better way to implement if no user logged in
      setCurrent({data: myFirstPlace[0], target: 'currentPlace'})
    }
    getStores()
    setLoading(false)
  }, [])

  // show details listener
  const onClickShowDetails = (item) => {
    setCurrent({data: item, target: 'currentItem'})
  }

  return (
    <ListContainer>
      <CardContainer>
        {currentPlace &&
          (!currentItem ? (
            <>
              <ItemsList action={onClickShowDetails} />
            </>
          ) : (
            <>
              <ItemDetail currentItem={currentItem} />
            </>
          ))}
      </CardContainer>
    </ListContainer>
  )
}

export default ItemsPage
