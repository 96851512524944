import React, {useContext, useEffect, useState} from 'react'
import AuthContext from '../../context/auth/AuthContext'
import PlaceContext from '../../context/place/PlaceContext'
import { CardContainer, ListContainer } from '../layout/Containers'
import HomeDetail from './HomeDetail'
import HomesList from './HomesList'

const HomesPage = () => {
  const placeContext = useContext(PlaceContext)
  const authContext = useContext(AuthContext)

  const {loadUser} = authContext
  const {currentHome, getPlaces, setCurrent, currentPlace} = placeContext

  // const [loading, setLoading] = useState(true)

  useEffect(async () => {
    await loadUser()
    const myFirstPlace = await getPlaces()
    if (myFirstPlace) {
      setCurrent({data: myFirstPlace[0], target: 'currentPlace'})
    }
    // setLoading(false)
  }, [])

  // show details listener
  const onClickShowDetails = (home) => {
    setCurrent({data: home, target: 'currentHome'})
  }

  return (
    <ListContainer>
      <CardContainer>
        {currentPlace &&
          (!currentHome ? (
            <>
              <HomesList action={onClickShowDetails} />
            </>
          ) : (
            <>
              {console.log('here we are')}
              <HomeDetail currentHome={currentHome} />
            </>
          ))}
      </CardContainer>
    </ListContainer>
  )
}

export default HomesPage
