import React, {useContext} from 'react'
import {Redirect, Route} from 'react-router'
import AuthContext from '../../context/auth/AuthContext'
import {Home} from '../Home'

export const PrivateRoute = ({component: Component, ...rest}) => {
  const authContext = useContext(AuthContext)

  const {isAuthenticated, loading} = authContext

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          !isAuthenticated ? <Redirect to='/' /> : <Component {...props} />
        }
      />
    </>
  )
}
