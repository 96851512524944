import React, {useReducer} from 'react'
import axios from 'axios'
import {setAuthToken} from '../../utils/setAuthToken'
import AuthContext from './AuthContext'
import authReducer from './authReducer'
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  CLEAR_ERRORS,
  SEND_VERIFICATION_EMAIL_SUCCESS,
  SEND_VERIFICATION_EMAIL_FAILURE,
  VERIFICATION_SUCCESS,
} from '../types'

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    user: null,
    isAuthenticated: false,
    isVerified: false,
    loading: true,
    error: null,
    verificationEmailSent: null,
  }

  const [state, dispatch] = useReducer(authReducer, initialState)

  // Load user
  const loadUser = async () => {
    setAuthToken(localStorage.token)

    console.log('loading user', localStorage.token, 'token')

    try {
      const res = await axios.get('/api/auth')
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      })
    } catch (err) {
      dispatch({
        type: AUTH_ERROR,
      })
    }
  }

  // Register user
  const register = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    try {
      const res = await axios.post('/api/users', formData, config)
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      })
      // loadUser()
    } catch (err) {
      dispatch({
        type: REGISTER_FAILURE,
        payload: err.response.data.msg,
      })
      console.log(err.response.data.msg)
    }
  }

  // Login user
  const login = async (formData) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    try {
      const res = await axios.post('/api/auth', formData, config)
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      })
      loadUser()
    } catch (err) {
      // HACK differentiate b/t express.validator error array and simple err object
      let payload
      err.response.data.errors
        ? (payload = err.response.data.errors.map((error) => error.msg))
        : (payload = err)
      dispatch({
        type: LOGIN_FAILURE,
        payload,
      })
    }
  }

  // Send verification email
  const sendVerificationEmail = async (token) => {
    const data = {token}
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    try {
      await axios.post('/api/user-verification', data, config)
      dispatch({type: SEND_VERIFICATION_EMAIL_SUCCESS})
    } catch (err) {
      dispatch({type: SEND_VERIFICATION_EMAIL_FAILURE})
    }
  }

  const verifyUser = async (token) => {
    try {
      const res = await axios.get(`/api/users/verify/${token}`)
      dispatch({type: VERIFICATION_SUCCESS, payload: res.data})
      
    } catch (err) {
      dispatch({type: AUTH_ERROR, payload: err})
    }
  }

  // Logout user
  const logout = () => {
    dispatch({type: LOGOUT})
    localStorage.removeItem('token')
    setAuthToken()
  }

  // Clear errors
  const clearErrors = () => {
    dispatch({type: CLEAR_ERRORS})
  }

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        user: state.user,
        isAuthenticated: state.isAuthenticated,
        isVerified: state.isVerified,
        loading: state.loading,
        error: state.error,
        verificationEmailSent: state.verificationEmailSent,
        register,
        login,
        sendVerificationEmail,
        verifyUser,
        logout,
        loadUser,
        clearErrors,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
export default AuthState
