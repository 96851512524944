import React from 'react'

export const ListChoice = ({store, list, shopClick, visible}) => {
  const getDaysAgo = (timestamp) => {
    let listCreatedDate = new Date(timestamp)
    let nowDate = new Date(Date.now())
    let msDiff = nowDate - listCreatedDate
    let msInADay = 86400000
    let daysAgo = Math.floor(msDiff / msInADay)

    return [daysAgo, nowDate.getDay() === listCreatedDate.getDay()]
  }

  const getTimeString = ([days, sameDay]) => {
    if (days === 0 && sameDay) {
      return 'Today'
    } else if ((days === 0 && !sameDay) || days === 1) {
      return 'Yesterday'
    } else {
      return `${days} days ago`
    }
  }

  const isVisible = !list.shopped ? true : visible ? true : false
  // const isVisible = list.shopped ? (visible ? true : false) : false

  return (
    <>
      {isVisible && (
        <div className='' badge={list.items.length}>
          <div
            className='flex justify-center items-center w-full h-24 rounded-full border-orange-600 border-2 shadow-md shadow-orange-400/50'
            onClick={() => shopClick(list)}
            href='#'
          >
            <div className='text-slate-700 text-xl'>{store.name}</div>
          </div>
          {/* <span className='secondary-content'>
            {getTimeString(getDaysAgo(list.created))}
          </span> */}
        </div>
      )}
    </>
  )
}
