import React, {useContext} from 'react'
import ListContext from '../../context/list/ListContext'
import StoreContext from '../../context/store/StoreContext'
import {createCheckBoxes} from '../../hooks/Custom'
import {CheckBox} from '../ui/FieldNames'
import {LocationsItems} from './LocationsItems'

export const ShoppingListContainer = () => {
  const listContext = useContext(ListContext)
  const storeContext = useContext(StoreContext)

  const {currentList, updateList} = listContext
  const {currentStore} = storeContext

  // get unique list of locations for list.items by comparing
  // against objects in store.items(.locations)
  const getUniqueLocations = (list, store) => {
    const locationsArr = Array.from(
      new Set(
        list.items
          .map((listItem) =>
            store.items.filter((item) => item.name == listItem.name)
          )
          .flat()
          .map((item) => item.locations)
          .flat()
          .map((location) => location.name)
          .flat()
          .sort()
      )
    )
    return locationsArr
  }

  // get items from shopping list which have an est. location
  // in the Store object
  // for ea. item in list, check that in the Store.items, there
  // is at least one item in the some() array that matches the item.name
  // as well as has a .locations arr. > 0
  const splitItemsByLocation = (list, store) => {
    let withLocations = []
    let withoutLocations = []
    store.items.map((storeItem) => {
      if (list.items.some((listItem) => listItem.name === storeItem.name)) {
        storeItem.locations.length > 0
          ? (withLocations = [...withLocations, storeItem])
          : (withoutLocations = [...withoutLocations, storeItem])
      }
    })
    return [withLocations, withoutLocations]
  }

  const uniqueLocations = getUniqueLocations(currentList, currentStore)
  const shoppedItems = currentList.items.filter((item) => item.shopped)
  const [itemsWithLocations, itemsWithoutLocations] = splitItemsByLocation(
    currentList,
    currentStore
  )

  const checkIfAllItemsShopped = (list) => {
    list.shopped = list.items
      .map((item) => item.shopped)
      .every((shoppedVal) => shoppedVal)
      ? true
      : false
    return list
  }

  const onChange = (e) => {
    const itemName = e.target.name
    if (e.target.checked) {
      currentList.items.find((item) => item.name === itemName).shopped = true
    } else if (!e.target.checked) {
      currentList.items.find((item) => item.name === itemName).shopped = false
    }
    const updatedList = checkIfAllItemsShopped(currentList)
    console.log('sending list as', updatedList)
    updateList(updatedList)
  }

  const getCheckBoxes = ({preChecked, collection, objToFilterOn}) => {
    collection =
      typeof objToFilterOn !== 'undefined' // just use collection if no filter obj
        ? collection.filter((item) =>
            item.locations
              .map((location) => location.name)
              .flat()
              .includes(objToFilterOn)
          )
        : collection
    const preCheckedNames = preChecked.map((checked) => checked.name)
    return createCheckBoxes(
      collection,
      preCheckedNames,
      onChange,
      objToFilterOn,
      ['full-width-checkbox', 'checkbox-buffer']
    )
  }

  return (
    <div style={{padding: '1rem', width: '100%', border: 'solid black 3px'}}>
      <ul>
        <>
          <div>
            {/* {console.log(
              'current list all shopped',
              currentList.items.map((item) => [item.shopped, item.name]),
              currentList.items.map((item) => item.shopped).every((t) => t),
              currentList
            )} */}
            {uniqueLocations.map((locationName, i) => (
              <LocationsItems
                key={i}
                locationName={locationName}
                checkBoxes={getCheckBoxes({
                  preChecked: shoppedItems,
                  collection: itemsWithLocations,
                  objToFilterOn: locationName,
                })}
              />
            ))}
            {itemsWithoutLocations.length > 0 && (
              <LocationsItems
                locationName={'Unorganized'}
                checkBoxes={getCheckBoxes({
                  preChecked: shoppedItems,
                  collection: itemsWithoutLocations,
                })}
              />
            )}
          </div>
        </>
      </ul>
    </div>
  )
}
