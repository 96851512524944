import React, {useReducer} from 'react'
import StoreContext from './StoreContext'
import storeReducer from './storeReducer'
import {
  ADD_STORE,
  DELETE_STORE,
  UPDATE_STORE,
  SET_CURRENT,
  CLEAR_CURRENT,
  GET_STORES,
  STORE_ERROR,
} from '../types'
import axios from 'axios'

const StoreState = (props) => {
  const initialState = {
    currentStoreItems: [],
    stores: null,
    currentStore: null,
  }

  const [state, dispatch] = useReducer(storeReducer, initialState)

  // Get one store
  const getStore = async (id) => {
    try {
      const res = await axios.get(`/api/stores/${id}`)
      return res.data
    } catch (err) {
      dispatch({type: STORE_ERROR, payload: err})
    }
  }

  // Get store by name
  const getStoreByName = async (name) => {
    const stores = await getStores()
    const store = stores.find((store) => store.name === name)
    return store
  }

  // Get stores
  const getStores = async () => {
    try {
      const res = await axios.get('/api/stores')
      dispatch({type: GET_STORES, payload: res.data})
      return res.data
    } catch (err) {
      dispatch({type: STORE_ERROR, payload: err})
    }
  }

  // Add store
  const addStore = async (store) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      const res = await axios.post('/api/stores', store, config)
      dispatch({type: ADD_STORE, payload: res.data})
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  // Update store
  const updateStore = async (data) => {
    try {
      const res = await axios.put(`/api/stores/${data._id}`, data)
      dispatch({type: UPDATE_STORE, payload: res.data})
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  // Delete store
  const deleteStore = async (store) => {
    try {
      const res = await axios.delete(`/api/stores/${store._id}`)
      dispatch({type: DELETE_STORE, payload: res.data})
    } catch (err) {
      console.error(err)
    }
  }

  // Set current store
  const setCurrentStore = (store) => {
    dispatch({type: SET_CURRENT, payload: store})
  }

  // Clear current store
  const clearCurrentStore = () => {
    dispatch({type: CLEAR_CURRENT})
  }

  return (
    <StoreContext.Provider
      value={{
        stores: state.stores,
        currentStore: state.currentStore,
        getStore,
        getStores,
        getStoreByName,
        addStore,
        updateStore,
        deleteStore,
        setCurrentStore,
        clearCurrentStore,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  )
}
export default StoreState
