import React, {useContext, useState} from 'react'
import ItemDetailHeader from './ItemDetailHeader'
import ModalActionAndTrigger from '../ui/ModalActionAndTrigger'
import RefContainerList1 from '../ui/RefContainerList1'
import PlaceContext from '../../context/place/PlaceContext'
import StoreContext from '../../context/store/StoreContext'
import {getDeepCopy, createCheckBoxes} from '../../hooks/Custom'
import {goBackTrigger, goBackConfirm} from '../../hooks/Custom'
import _ from 'lodash'
import { PencilIcon } from '../../img/images'


const ItemDetail = (props) => {
  const {currentItem} = props

  const placeContext = useContext(PlaceContext)
  const storeContext = useContext(StoreContext)

  const {currentPlace, updatePlace, setCurrent, clearCurrent} = placeContext
  const {stores, updateStore} = storeContext

  // used to get stores (across schemas) for useState hooks
  const getCurrentItemStores = (currItem) => {
    return stores.filter((store) =>
      store.items.map((storeItem) => storeItem.name).includes(currItem.name)
    )
  }

  console.log(stores, 'stores')


  // required to add stores prop to currentItem bc it's not passed with that prop
  // and it is compared using _.isEqual() to render the back button properly
  currentItem.stores = getCurrentItemStores(currentItem)

  // used to get stores (across schemas) for editing store checkboxes
  // in onChange
  const getStoresByName = (store) => {
    return stores.find((s) => s.name === store)
  }

  // this stores the changes to-be-saved while the user is editing and
  // is what gets passed on submit
  const [thisItem, setThisItem] = useState({
    name: currentItem.name,
    homes: getDeepCopy(currentItem.homes), // TODO 01 make more like StoreDetail -- the deep copy goes on the shadow side
    stores: getCurrentItemStores(currentItem), // all stores that current item is in
  })

  // this is what gets edited from the modal and is necessary for
  // checkbox management and UI updating
  const [thisItemShadow, setThisItemShadow] = useState({
    name: currentItem.name,
    homes: currentItem.homes,
    stores: getCurrentItemStores(currentItem), // all stores that current item is in
  })

  // TODO - 02 would replacing this with a thisStoreShadow/thisStore setup
  // be better here?
  // when interacting w/ checkboxes for stores, save which ones to
  // remove vs add in the moment to prevent reiterating over them
  // in onSubmit
  const [storeEdits, setStoreEdits] = useState({add: [], remove: []})

  const addToEditsList = (listName, addend) => {
    setStoreEdits((prevState) => ({
      ...prevState,
      [listName]: [...prevState[listName], addend],
    }))
  }

  const removeFromEditsList = (listName, addend) => {
    setStoreEdits((prevState) => ({
      ...prevState,
      [listName]: prevState[listName].filter((s) => s.name !== addend.name),
    }))
  }

  // simple variable assignments holding names from nested objects
  const itemStoreNames = thisItem.stores.map(
    (itemStoreObj) => itemStoreObj.name
  )
  const itemHomeNames = thisItem.homes.map((home) => home.name)

  // TODO 03 refactor!
  const onChange = (e) => {
    // whacky bit of state management across several checkboxes complicated by
    // whether or not they were selected prior to accessing the ItemDetails page
    let stores
    let homes
    const checkBoxGroup = e.target.getAttribute('checkBoxGroup')
    if (checkBoxGroup === 'stores') {
      let itemName = e.target.name
      let store = getStoresByName(itemName)
      if (e.target.checked) {
        stores = [...thisItemShadow.stores, store]
        if (!itemStoreNames.includes(store.name)) {
          addToEditsList('add', store)
        } else {
          // store choice was selected prior to access
          removeFromEditsList('remove', store)
        }
      } else if (!e.target.checked) {
        stores = thisItemShadow.stores.filter((s) => s.name !== store.name)
        if (itemStoreNames.includes(store.name)) {
          // store choice was selected prior to access
          addToEditsList('remove', store)
        } else {
          // store choice was NOT selected prior to access
          removeFromEditsList('add', store)
        }
      }
    } else if (checkBoxGroup === 'homes') {
      let homeName = e.target.name
      homes = thisItemShadow.homes
      let home = currentPlace.homes.find((home) => home.name === homeName)
      homes = e.target.checked
        ? [...homes, home]
        : homes.filter((stateHome) => stateHome.name !== home.name)
    }
    setThisItemShadow((prevState) => ({
      ...prevState,
      homes: homes || prevState['homes'],
      stores: stores || prevState['stores'],
    }))
  }

  const onSubmit = () => {
    updatePlaceItemHomes({currentPlace, item: thisItem})
    updateStoreItems()
    clearCurrent({target: 'currentItem'})
  }

  const deleteItem = () => {
    const items = currentPlace.items.filter(
      (itm) => itm.name !== currentItem.name
    )
    updatePlace({...currentPlace, items})
    setCurrent({data: {...currentPlace, items}, target: 'currentPlace'})
    clearCurrent({target: 'currentItem'})
  }

  // update the homes for the currentItem after onSubmit
  const updatePlaceItemHomes = ({currentPlace, item}) => {
    currentPlace.items.map((placeItem) => {
      placeItem.name === item.name
        ? (placeItem.homes = item.homes)
        : (placeItem.homes = placeItem.homes)
    })
    updatePlace(currentPlace)
  }

  // update the stores for the currentItem after onSubmit
  const updateStoreItems = () => {
    processStoresToAdd()
    processStoresToRemove()
  }

  const processStoresToAdd = () => {
    storeEdits.add.map((editedStore) => {
      editedStore.items = [...editedStore.items, {name: currentItem.name}]
      updateStore(editedStore)
    })
  }

  const processStoresToRemove = () => {
    storeEdits.remove.map((editedStore) => {
      editedStore.items = editedStore.items.filter(
        (item) => item.name !== currentItem.name
      )
      updateStore(editedStore)
    })
  }

  // action after clicking OK from the modal
  const onOkModal = () => {
    const {name, homes, stores} = getDeepCopy(thisItemShadow)
    setThisItem((prevState) => ({...prevState, name, homes, stores}))
  }

  const storeCheckBoxes = createCheckBoxes(
    stores,
    itemStoreNames,
    onChange,
    'stores',
    ['full-width-checkbox']
  )

  const homeCheckBoxes = createCheckBoxes(
    currentPlace.homes,
    itemHomeNames,
    onChange,
    'homes',
    ['full-width-checkbox', 'checkbox-buffer']
  )

  // logic to determine whether a back button will simply 'go back' w/ no changes
  // made or whether it will warn that changes will be lost
  const backButton = _.isEqual(thisItem, currentItem) ? (
    <a onClick={() => clearCurrent({target: 'currentItem'})}>{goBackTrigger}</a>
  ) : (
    <ModalActionAndTrigger
      modalId='goBackConfirmModal'
      modalContent={goBackConfirm}
      action={() => clearCurrent({target: 'currentItem'})}
      trigger={goBackTrigger}
    />
  )

  const editHomesHeader = <span className='center'>{thisItem.name} Homes</span>
  const editStoresHeader = (
    <span className='center'>{thisItem.name} Stores</span>
  )

  const editIcon = <PencilIcon color='fill-gray-600' height='10' width='10' />


  return (
    <div className='container'>
      <ItemDetailHeader
        backButton={backButton}
        deleteClick={deleteItem}
        item={thisItem}
        changeAction={() => console.log('change action')}
      />
      <div className='flex justify-center'>
        <button
          className='max-w-sm bg-emerald-400 rounded-xl border-gray-200 border w-full h-16'
          onClick={() => onSubmit()}
        >
          <div className='center-text-vertical uppercase shadow-2xl shadow-white text-white font-semibold'>
            Save
          </div>
        </button>
      </div>
      <RefContainerList1
        listRef={thisItem.homes}
        headerText='Homes'
        editButton={
          <ModalActionAndTrigger
            modalId='editHomesModal'
            action={onOkModal}
            modalContent={homeCheckBoxes}
            trigger={editIcon}
            header={editHomesHeader}
          />
        }
      />
      <RefContainerList1
        listRef={thisItem.stores}
        headerText='Stores'
        editButton={
          <ModalActionAndTrigger
            modalId='editStoresModal'
            action={onOkModal}
            modalContent={storeCheckBoxes}
            trigger={editIcon}
            header={editStoresHeader}
          />
        }
      />
    </div>
  )
}

export default ItemDetail
