import React, {useState} from 'react'
import {CheckBox, NameField} from '../components/ui/FieldNames'

export const useFormHandler = (submitFunc, initialVal) => {
  const [state, setState] = useState(initialVal)
  const changeVal = (val) => {
    setState({...state, ...val})
  }
  const submitVal = async (val) => {
    const res = await submitFunc(val)
    return res
  }

  return [changeVal, submitVal, state]
}

export const getDeepCopy = (x) => JSON.parse(JSON.stringify(x))

///////////////////////////
// Checkboxes for modals //
///////////////////////////

const generateCheckBoxElement = (content, i) => {
  const {valueObj, changeAction, idString, labelString, checked, metaOptions} =
    content
  return (
    <CheckBox
      valueObj={valueObj}
      action={changeAction}
      idString={idString}
      labelString={labelString}
      checked={checked}
      metaOptions={metaOptions}
      key={i}
    />
  )
}

const createCheckBox = (checkBoxContent, i) => {
  const {
    valueObj,
    comparisonArr,
    changeAction,
    checkBoxGroup,
    extraClasses,
    required = false,
    disabled = false,
  } = checkBoxContent
  const checked = comparisonArr.includes(valueObj.name) ? true : false
  const content = {
    valueObj,
    comparisonArr,
    changeAction,
    checked,
    required,
    disabled,
    metaOptions: {checkBoxGroup, extraClasses},
  }

  const element = generateCheckBoxElement(content, i)
  return {content, element}
}

export const createCheckBoxes = (
  sourceArr, // arr of objects
  comparisonArr, // arr of names to compare against sourceArr
  changeAction,
  checkBoxGroup, // an identifier for the parent container
  extraClasses
) =>
  sourceArr.map((sourceObj) => (
    <>
      {
        createCheckBox({
          valueObj: sourceObj,
          comparisonArr,
          changeAction,
          checkBoxGroup,
          extraClasses,
        }).element
      }
    </>
  ))

////////// END ////////////

export const titleCase = (word) =>
  word.replace(word.slice(0, 1), word.slice(0, 1).toUpperCase())

export const createTextInputs = (props) => {
  const {
    changeAction,
    idStrings,
    labelStrings = idStrings.map((idString) => titleCase(idString)),
    valueObjs,
    required = true,
    metaOptions,
  } = props

  return {
    elements: valueObjs.map((value, i) => (
      <NameField
        key={i}
        onChange={changeAction}
        idString={idStrings[i]}
        labelString={labelStrings[i]}
        valueObj={value}
        required={required}
        metaOptions={metaOptions}
      />
    )),
    content: props,
  }
}

export const deleteTrigger = <i className='material-icons'>delete</i>
export const deleteConfirm = 'Deleting cannot be undone!  Continue?'
export const goBackTrigger = <i className='material-icons'>arrow_back</i>
export const goBackConfirm = 'Unsaved changes will be lost.  Continue?'
