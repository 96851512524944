import React, {useEffect, useState} from 'react'

export const NameField = ({
  valueObj,
  onChange,
  idString,
  labelString,
  required,
}) => {
  return (
    <div>
      <label htmlFor={idString}>{labelString}</label>
      <input
        type='text'
        name={idString}
        id={idString}
        value={valueObj.name}
        onChange={onChange}
        required={required}
      />
    </div>
  )
}

export const TypeField = ({
  valueObj,
  onChange,
  idString,
  labelString,
  required,
}) => {
  return (
    <div>
      <label htmlFor={idString}>{labelString}</label>
      <input
        type='text'
        name={idString}
        id={idString}
        value={valueObj.type}
        onChange={onChange}
        required={required}
      />
    </div>
  )
}

export const SelectBox = ({
  valueObj,
  idString,
  labelString,
  onChange,
  options,
  metaOptions,
}) => {
  const {required, multiple, disabledPrompt} = metaOptions
  return (
    <>
      <div className='input-field col s6'>
        <select
          value={valueObj.store}
          name={idString}
          onChange={onChange}
          required={required}
          multiple={multiple}
        >
          {disabledPrompt && (
            <option value='' disabled defaultValue>
              Choose your option
            </option>
          )}
          {options.map((option, index) => (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          ))}
        </select>
        <label>{labelString}</label>
      </div>
    </>
  )
}

export const CheckBox = ({
  valueObj,
  idString,
  labelString,
  action,
  checked,
  metaOptions,
}) => {
  const [checkState, setcheckState] = useState(checked)
  const {required, disabled, style, checkBoxGroup, extraClasses} = metaOptions
  useEffect(() => {}, [])

  const onChange = (e) => {
    setcheckState(!checkState)
    action(e)
  }

  return (
    <span>
      <label>
        <input
          type='checkbox'
          name={idString || valueObj.name}
          onChange={onChange}
          checkBoxGroup={checkBoxGroup}
          required={required}
          checked={checkState}
          disabled={disabled}
        />
        <span
          className={
            typeof extraClasses !== 'undefined'
              ? `${extraClasses.join(' ')}`
              : ``
          }
        >
          {labelString || valueObj.name}
        </span>
      </label>
    </span>
  )
}
