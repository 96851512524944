import React, {useContext, useEffect, useState} from 'react'
import AuthContext from '../../context/auth/AuthContext'
import StoresList from './StoresList'
import StoreDetail from './StoreDetail'
import CategoryListPage from '../ui/CategoryListPage'
import CategoryListCollection from '../ui/CategoryListCollection'
import M from 'materialize-css/dist/js/materialize.min.js'
import TextInputModal from '../ui/TextInputModal'
import {createTextInputs, useFormHandler} from '../../hooks/Custom'
import StoreContext from '../../context/store/StoreContext'
import {CardContainer, ListContainer} from '../layout/Containers'
import { PlusIcon } from '../../img/images'

const StoresPage = () => {
  const storeContext = useContext(StoreContext)
  const authContext = useContext(AuthContext)

  const {
    getStores,
    addStore,
    updateStore,
    deleteStore,
    currentStore,
    setCurrentStore,
    clearCurrentStore,
  } = storeContext

  const {loadUser} = authContext

  const [loading, setLoading] = useState(false)
  const [theseStores, setTheseStores] = useState()

  useEffect(async () => {
    setLoading(true)
    await loadUser()
    const elem = document.querySelector('#newStoreModal1')
    M.Modal.init(elem, {
      onOpenEnd() {
        document.querySelector('#name').focus()
      },
      onCloseEnd() {
        document.querySelector('#name').value = ''
      },
    })
    clearCurrentStore()
    const s = await getStores()
    setTheseStores(s)
    setLoading(false)
  }, [])

  const [changeVal, submitVal, state] = useFormHandler(addStore, {
    name: '',
  })

  // show details listener
  const onClickShowDetails = (store) => {
    setCurrentStore(store)
  }

  const storeAdd = async (e) => {
    setLoading(true)
    const newStore = await submitVal(state)
    setTheseStores((prevState) => [...prevState, newStore])
    changeVal({name: ''})
    setLoading(false)
  }

  const storeUpdate = ({thisStore}) => {
    updateStore(thisStore)
    setTheseStores((prevState) =>
      prevState.map((store) =>
        store.name === thisStore.name ? thisStore : store
      )
    )
    clearCurrentStore()
  }

  const storeDelete = () => {
    deleteStore(currentStore)
    setTheseStores((prevState) =>
      prevState.filter((store) => store._id !== currentStore._id)
    )
    clearCurrentStore()
  }

  const onChange = (e) => {
    e.preventDefault()
    changeVal({[e.target.name]: e.target.value})
  }

  const getModalContent = () => {
    return createTextInputs({
      changeAction: onChange,
      idStrings: ['name'],
      valueObjs: [state],
      metaOptions: {},
    })
  }

  const newStoreHeader = (
    <div
      className='center'
      style={{
        lineHeight: '2',
        fontSize: '2em',
        marginTop: '2px',
      }}
    >
      New Store
    </div>
  )

  const insertTrigger = (
    <PlusIcon color='fill-gray-700' height='10' width='10' />
  )

  return (
    <ListContainer>
      <CardContainer>
        {!loading &&
          (!currentStore ? (
            <>
              <CategoryListPage
                model={theseStores}
                headerString='Stores'
                insertButton={
                  <TextInputModal
                    modalId='newStoreModal1'
                    modalContent={getModalContent}
                    action={storeAdd}
                    trigger={insertTrigger}
                    triggerClassNames=''
                    header={newStoreHeader}
                  />
                }
                collection={
                  <CategoryListCollection
                    collectionArray={theseStores}
                    action={onClickShowDetails}
                  />
                }
              />
            </>
          ) : (
            <>
              <StoreDetail
                storeDelete={storeDelete}
                storeUpdate={storeUpdate}
                currentStore={currentStore}
              />
            </>
          ))}
      </CardContainer>
    </ListContainer>
  )
}

export default StoresPage
