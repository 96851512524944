import React, {useReducer} from 'react'
import uuid from 'uuid'
import PlaceContext from './PlaceContext'
import placeReducer from './placeReducer'
import {
  GET_PLACES,
  ADD_PLACE,
  UPDATE_PLACE,
  DELETE_PLACE,
  SET_CURRENT,
  CLEAR_CURRENT,
} from '../types'
import axios from 'axios'

const PlaceState = (props) => {
  const initialState = {
    places: [],
    currentPlace: null,
    currentItem: null,
    currentHome: null,
  }

  const [state, dispatch] = useReducer(placeReducer, initialState)

  // Get one place
  const getPlace = async (id) => {
    try {
      const res = await axios.get(`/api/places/${id}`)
      console.log('hi')
      return res.data
    } catch (err) {
      console.log(err, 'err')
    }
  }

  // Get places
  const getPlaces = async () => {
    try {
      const res = await axios.get('/api/places')
      dispatch({type: GET_PLACES, payload: res.data})
      return res.data
    } catch (err) {
      console.log(err, 'err')
    }
  }

  // Add place
  const addPlace = async ({name, type, stores}) => {
    const data = {name, type, stores} // TODO revisit for refactoring -- this is essentially unpacking and then repacking (see updatePlace for contrast)
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    try {
      const res = await axios.post('/api/places', data, config)
      dispatch({type: ADD_PLACE, payload: res.data})
    } catch (err) {
      console.error(err)
    }
  }

  // TODO det. if giving a generic prop as the param is a good idea
  // Update place
  // name, homes, items
  const updatePlace = async (data) => {
    try {
      console.log('sending', data)

      const res = await axios.put(`/api/places/${data._id}`, data)
      console.log('result', res.data)
      dispatch({type: UPDATE_PLACE, payload: res.data})
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  // Delete place
  const deletePlace = async (place) => {
    try {
      const res = await axios.delete(`/api/places/${place._id}`)
      dispatch({type: DELETE_PLACE, payload: res.data})
    } catch (err) {
      console.error(err)
    }
  }

  // Set current thing
  const setCurrent = ({data, target}) => {
    dispatch({type: SET_CURRENT, payload: {data, target}})
  }

  // Clear current thing
  const clearCurrent = ({target}) => {
    dispatch({type: CLEAR_CURRENT, payload: {target}})
  }

  return (
    <PlaceContext.Provider
      value={{
        places: state.places,
        currentPlace: state.currentPlace,
        currentItem: state.currentItem,
        currentHome: state.currentHome,
        getPlace,
        getPlaces,
        addPlace,
        updatePlace,
        deletePlace,
        setCurrent,
        clearCurrent,
      }}
    >
      {props.children}
    </PlaceContext.Provider>
  )
}
export default PlaceState
