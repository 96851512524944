import React, {useEffect} from 'react'
import M, {elementOrParentIsFixed} from 'materialize-css'
import Modal from '../Modal'
import {OkButton, CancelButton} from '../ui/components/Buttons'

const TextInputModal = ({
  modalId,
  modalContent,
  action,
  trigger,
  triggerClassNames,
  additionalClasses,
  header,
}) => {
  // Fork of ModalActionAndTrigger.js that allows for multiple inputs to be
  // passed and actions related to a) focusing on the first input and b) clearing
  // each input on modal disappearance

  const {elements, content} = modalContent()
  const modalActions = {
    focusInput() {
      document.querySelector(`#${content.idStrings[0]}`).focus()
    },
    clearInputs() {
      content.idStrings.map(
        (idString) => (document.querySelector(`#${idString}`).value = '')
      )
    },
  }

  useEffect(() => {
    const modalWindow = document.querySelector(`#${modalId}`)
    M.Modal.init(modalWindow, {
      onOpenEnd() {
        modalActions.focusInput()
      },
      onCloseEnd() {
        modalActions.clearInputs()
      },
    })
  }, [])

  return (
    <>
      <a // please pardon the funky syntax for avoiding unneeded whitespace in the className attribute... help is being sought
        className={`modal-trigger${
          typeof triggerClassNames !== 'undefined'
            ? ` ${triggerClassNames}`
            : ``
        }${
          typeof additionalClasses !== 'undefined'
            ? ` ${additionalClasses}`
            : ``
        }`}
        href={`#${modalId}`}
      >
        {/* <i className='material-icons'>sort</i> */}
        <div className="pr-2">{trigger}</div>
      </a>
      <Modal
        header={header}
        modalId={modalId}
        modalContent={elements.map((elem) => elem)}
      >
        <CancelButton />
        <OkButton action={action} />
      </Modal>
    </>
  )
}

//<NewHomeForm newHome={newHome} onChange={onChange} />
//newHomeModal1
export default TextInputModal
