import {
  GET_PLACES,
  ADD_PLACE,
  UPDATE_PLACE,
  DELETE_PLACE,
  SET_CURRENT,
  CLEAR_CURRENT,
  PUSH_CURRENT,
  POP_CURRENT,
} from '../types'

export default (state, action) => {
  switch (action.type) {
    case GET_PLACES:
      return {
        ...state,
        places: action.payload,
      }
    case ADD_PLACE:
      return {
        ...state,
        places: [...state.places, action.payload],
      }
    case UPDATE_PLACE:
      return {
        ...state,
        places: state.places.map((place) =>
          place._id === action.payload._id ? action.payload : place
        ),
      }
    case DELETE_PLACE:
      return {
        ...state,
        places: state.places.filter(
          (place) => place._id !== action.payload._id
        ),
      }
    case SET_CURRENT:
      return {
        ...state,
        [action.payload.target]: action.payload.data,
      }
    case CLEAR_CURRENT:
      return {
        ...state,
        [action.payload.target]: null,
      }
    default:
      return state
  }
}
