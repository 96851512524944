import React from 'react'

export const SimpleContainer = (props) => {
  return (
    <div className='flex flex-grow content-center justify-center pt-10 w-full'>
      <div className='max-w-3xl w-3/4 md:w-1/2'>{props.children}</div>
    </div>
  )
}

export const ListContainer = (props) => {
  return (
    <div className='flex flex-grow bg-gray-50 content-center justify-center pt-10'>
      <div className='flex w-full md:w-3/4 lg:w-3/5 xl:w-2/5'>
      {/* <div className='flex w-full md:w-5/6 lg:w-3/4 2xl:w-2/5'> */}
        {props.children}
      </div>
    </div>
  )
}

export const CardContainer = (props) => {
  return (
    <div className='flex flex-col flex-grow bg-white border-2 border-gray-200 rounded-2xl mx-4 sm md:mx-0 mb-10 shadow-lg'>
      {props.children}
    </div>
  )
}
