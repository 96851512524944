import React from 'react'

// ;<svg
//   xmlns='http://www.w3.org/2000/svg'
//   className='h-5 w-5'
//   viewBox='0 0 20 20'
//   fill='currentColor'
// >
//   <path
//     fillRule='evenodd'
//     d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
//     clipRule='evenodd'
//   />
// </svg>

export const HelpIcon = ({width = '5', height = '5', color = 'fill-black'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-${height} w-${width} ${color}`}
      viewBox='0 0 20 20'
    >
      <path
        fillRule='evenodd'
        d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export const CartIcon = ({width = '5', height = '5', color = 'fill-black'}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-${height} w-${width} ${color}`}
      viewBox='0 0 20 20'
    >
      <path d='M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z' />
    </svg>
  )
}

export const TrashIcon = ({
  width = '5',
  height = '5',
  color = 'fill-black',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-${height} w-${width} ${color}`}
      viewBox='0 0 20 20'
    >
      <path
        fillRule='evenodd'
        d='M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export const PencilIcon = ({
  width = '5',
  height = '5',
  color = 'fill-black',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-${height} w-${width} ${color}`}
      viewBox='0 0 20 20'
    >
      <path d='M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z' />
    </svg>
  )
}

export const PlusIcon = ({width = '5', height = '5', color = 'fill-black'}) => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className={`h-${height} w-${width} ${color}`}
        viewBox='0 0 20 20'
      >
        <path
          fillRule='evenodd'
          d='M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z'
          clipRule='evenodd'
        />
      </svg>
    </>
  )
}

export const HomeIcon = ({width = '5', height = '5', color = 'fill-black'}) => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className={`h-${height} w-${width} ${color}`}
        viewBox='0 0 20 20'
        fill='currentColor'
      >
        <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
      </svg>
    </>
  )
}

export const HeroMan = () => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='1200'
        height='600'
        viewBox='0 0 3000 3000'
      >
        {' '}
        <defs>
          {' '}
          <clipPath id='clip-iPhone_XR_XS_Max_11_6'>
            {' '}
            <rect width='3000' height='3000' />{' '}
          </clipPath>{' '}
        </defs>{' '}
        <g
          id='iPhone_XR_XS_Max_11_6'
          data-name='iPhone XR, XS Max, 11 – 6'
          clipPath='url(#clip-iPhone_XR_XS_Max_11_6)'
        >
          {' '}
          <rect width='3000' height='3000' fill='#fff' />{' '}
          <g
            id='Group_381'
            data-name='Group 381'
            transform='translate(-261 228.075)'
          >
            {' '}
            <path
              id='Path_1141'
              data-name='background_shape'
              d='M4666.664,8393.566c482.167,146.355,964.123,70.87,1436.566-413.239,884.708-906.562-1402.187-965.049-1402.187-965.049s-1402.182-935.809-1285.333,0S4371.994,8304.118,4666.664,8393.566Z'
              transform='translate(-3096.819 -6185.282)'
              fill='#7b8e87'
              opacity='0.75'
            />{' '}
            <path
              id='Path_151'
              data-name='phone_stand'
              d='M2044.629,503.275l-51.353-6.526a61.359,61.359,0,0,0-16.38,19.13l-18.831,34.31-.086-.12L1672.1,968.828H1821.72c52.794-100.914-26.04,55.908,179.326-355.774C2001.046,613.054,2050.977,509.084,2044.629,503.275Z'
              transform='translate(195.453 232.248)'
              fill='#f36d27'
            />{' '}
            <rect
              id='Rectangle_329'
              data-name='phone_bezel'
              width='105.888'
              height='186.218'
              rx='0.843'
              transform='translate(2169.334 619.703)'
              fill='#a13c08'
            />{' '}
            <rect
              id='Rectangle_330'
              data-name='phone_screen'
              width='94.552'
              height='163.546'
              rx='0.843'
              transform='translate(2175.002 631.039)'
              fill='#f6915d'
            />{' '}
            <path
              id='Path_1142'
              data-name='Path 1142'
              d='M2.834,0A2.834,2.834,0,1,1,0,2.834,2.834,2.834,0,0,1,2.834,0Z'
              transform='translate(3202.817 364.708)'
              fill='#989898'
            />{' '}
            <g
              id='Group_327'
              data-name='Group 327'
              transform='translate(393.296 267.925)'
            >
              {' '}
              <g
                id='Group_303'
                data-name='Group 303'
                transform='translate(0 507.247)'
              >
                {' '}
                <path
                  id='Rectangle_289'
                  data-name='fridge_side'
                  d='M4.762,0h202.5a0,0,0,0,1,0,0V1258.646a0,0,0,0,1,0,0H4.762A4.762,4.762,0,0,1,0,1253.884V4.762A4.762,4.762,0,0,1,4.762,0Z'
                  fill='#f6915d'
                />{' '}
                <path
                  id='Rectangle_290'
                  data-name='fridge_face'
                  d='M0,0H517.749a4.762,4.762,0,0,1,4.762,4.762V1253.884a4.762,4.762,0,0,1-4.762,4.762H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z'
                  transform='translate(207.259)'
                  fill='#f9b693'
                />{' '}
                <rect
                  id='Rectangle_291'
                  data-name='fridge_gap_side'
                  width='207.259'
                  height='23.115'
                  transform='translate(0 325.06)'
                  fill='#f36d27'
                />{' '}
                <rect
                  id='Rectangle_292'
                  data-name='fridge_gap_face'
                  width='522.511'
                  height='23.115'
                  transform='translate(207.259 325.06)'
                  fill='#f6915d'
                />{' '}
                <rect
                  id='Rectangle_293'
                  data-name='fridge_handle_top'
                  width='15.335'
                  height='92.967'
                  rx='1.16'
                  transform='translate(249.329 193.27)'
                  fill='#f36d27'
                />{' '}
                <rect
                  id='Rectangle_294'
                  data-name='fridge_handle_bottom'
                  width='15.335'
                  height='176.756'
                  rx='1.16'
                  transform='translate(249.329 398.641)'
                  fill='#f36d27'
                />{' '}
              </g>{' '}
              <g
                id='Group_321'
                data-name='Group 321'
                transform='translate(805.577 812.27)'
              >
                {' '}
                <path
                  id='Path_889'
                  data-name='leg3'
                  d='M2499.227,2824.062l-96.075,776.766h-19.076l96.075-776.766Z'
                  transform='translate(-1427.457 -2647.198)'
                  fill='#fcdac9'
                />{' '}
                <g
                  id='Group_316'
                  data-name='Group 316'
                  transform='translate(0 176.864)'
                >
                  {' '}
                  <path
                    id='Path_928'
                    data-name='leg1'
                    d='M2330.45,2824.062l-25.7,207.821-70.372,568.945H2215.3l70.168-567.319,25.9-209.446Z'
                    transform='translate(-2215.301 -2824.062)'
                    fill='#fcdac9'
                  />{' '}
                  <path
                    id='Path_929'
                    data-name='leg2'
                    d='M2374.988,3600.827h-19.076L2283.6,3016.132l-23.756-192.07h19.076l23.557,190.479Z'
                    transform='translate(-2007.397 -2824.062)'
                    fill='#fcdac9'
                  />{' '}
                  <path
                    id='Path_930'
                    data-name='leg1_shadow'
                    d='M2253.585,2824.062h19.074l-25.7,207.821-19.276,1.625Z'
                    transform='translate(-2157.512 -2824.062)'
                    fill='#f6915d'
                  />{' '}
                  <path
                    id='Path_931'
                    data-name='leg2_shadow'
                    d='M2278.916,2824.062l23.557,190.479-18.877,1.591-23.756-192.07Z'
                    transform='translate(-2007.397 -2824.062)'
                    fill='#f6915d'
                  />{' '}
                </g>{' '}
                <g
                  id='Group_317'
                  data-name='Group 317'
                  transform='translate(1209.062 176.864)'
                >
                  {' '}
                  <path
                    id='Path_932'
                    data-name='leg4'
                    d='M2543.765,3600.827h-19.077l-82.19-664.534-13.884-112.231h19.075l13.686,110.636Z'
                    transform='translate(-2428.614 -2824.062)'
                    fill='#fcdac9'
                  />{' '}
                  <path
                    id='Path_933'
                    data-name='leg4_shadow'
                    d='M2461.375,2934.7l-18.877,1.6-13.884-112.231h19.075Z'
                    transform='translate(-2428.614 -2824.062)'
                    fill='#f6915d'
                  />{' '}
                </g>{' '}
                <g
                  id='Group_318'
                  data-name='Group 318'
                  transform='translate(35.017 146.212)'
                >
                  {' '}
                  <path
                    id='Path_934'
                    data-name='table_face'
                    d='M3242.1,2818.654H2269.262v30.652H3242.1a15.325,15.325,0,0,0,15.326-15.327h0A15.321,15.321,0,0,0,3242.1,2818.654Z'
                    transform='translate(-1998.428 -2818.654)'
                    fill='#fcdac9'
                  />{' '}
                  <path
                    id='Path_935'
                    data-name='table_side'
                    d='M2294.29,2818.654h-57.484a15.325,15.325,0,0,0-15.327,15.325h0a15.33,15.33,0,0,0,15.327,15.327h255.507v-30.652Z'
                    transform='translate(-2221.479 -2818.654)'
                    fill='#f36d27'
                  />{' '}
                </g>{' '}
                <g
                  id='Group_319'
                  data-name='Group 319'
                  transform='translate(646.459 99.756)'
                >
                  {' '}
                  <path
                    id='Path_936'
                    data-name='phone_stand_base'
                    d='M2603.606,2822.643v-7.809a4.374,4.374,0,0,0-4.375-4.376H2417.917a16.561,16.561,0,0,0-16.562,16.561h0v13.326h0a16.561,16.561,0,0,0,16.562,16.563h181.315a4.37,4.37,0,0,0,4.375-4.372v-29.894Z'
                    transform='translate(-2401.355 -2810.458)'
                    fill='#f36d27'
                  />{' '}
                </g>{' '}
                <g
                  id='Group_320'
                  data-name='Group 320'
                  transform='translate(182.673)'
                >
                  {' '}
                  <path
                    id='Path_940'
                    data-name='plant_bottom'
                    d='M2255.191,2805.5h85.559a7.037,7.037,0,0,1,6.988,7.878l-7.236,60.462a7.037,7.037,0,0,1-6.99,6.206h-71.083a7.034,7.034,0,0,1-6.987-6.206l-7.239-60.462A7.038,7.038,0,0,1,2255.191,2805.5Z'
                    transform='translate(-2244.63 -2733.837)'
                    fill='#fcdac9'
                  />{' '}
                  <path
                    id='Path_941'
                    data-name='plant_top'
                    d='M2330.242,2839.853v-27.492l-16.448,31.252v-50.755l-12.925,31.957-12.922-31.957v50.755l-16.45-31.252v27.492l-23.968-38.536,10.56,63.209h85.56l10.56-63.209Z'
                    transform='translate(-2247.53 -2792.858)'
                    fill='#34d399'
                  />{' '}
                </g>{' '}
              </g>{' '}
            </g>{' '}
            <g
              id='Group_25'
              data-name='Group 25'
              transform='translate(2407.601 313.384)'
            >
              {' '}
              <g
                id='Group_23'
                data-name='Group 23'
                transform='translate(220.696 0)'
              >
                {' '}
                <g
                  id='Group_18'
                  data-name='Group 18'
                  transform='translate(7.078 85.629)'
                >
                  {' '}
                  <path
                    id='Path_63'
                    data-name='man_face'
                    d='M672.092,496.56c76.018,12.666,170.736-15.628,182.612-33.642,27.527,41.94,41.505,40.628,41.505,40.628V535l6.213-6.219c1.916-4.332,4.96-8.636,9.513-8.636,25.03,0,26.777,69.618-15.349,73.643-.83,2.729-1.684,5.279-2.561,7.585V712.807h-95.02V668.98a69.229,69.229,0,0,1-18.13,2.764c-37.464,0-101.3-55.089-108.539-142.959C671.9,512.2,672.092,496.56,672.092,496.56Z'
                    transform='translate(-672.05 -462.918)'
                    fill='#fcbeb7'
                  />{' '}
                </g>{' '}
                <path
                  id='Path_64'
                  data-name='hair'
                  d='M796.346,448.173c65.53,0,78.637,34.954,78.637,34.954,27.963,0,70.774,51.111,45,107.907l-.134.133L905.13,605.888V574.43s-13.978,1.313-41.505-40.628c-11.876,18.014-106.594,46.308-182.612,33.642,0,0-.192,15.639.244,32.225v.046C652.859,517.588,709.846,448.173,796.346,448.173Z'
                  transform='translate(-673.893 -448.173)'
                  fill='#6b2805 '
                />{' '}
                <g
                  id='Group_19'
                  data-name='Group 19'
                  transform='translate(145.393 140.781)'
                >
                  {' '}
                  <path
                    id='Path_65'
                    data-name='Path 65'
                    d='M696.033,492.694a5.241,5.241,0,0,0,5.244-5.238v-9.8a5.241,5.241,0,1,0-10.482,0v9.8A5.24,5.24,0,0,0,696.033,492.694Z'
                    transform='translate(-690.795 -472.415)'
                    fill='#311643'
                  />{' '}
                </g>{' '}
                <g
                  id='Group_20'
                  data-name='Group 20'
                  transform='translate(48.19 140.781)'
                >
                  {' '}
                  <path
                    id='Path_66'
                    data-name='Path 66'
                    d='M712.772,492.694a5.241,5.241,0,0,0,5.244-5.238v-9.8a5.241,5.241,0,1,0-10.482,0v9.8A5.24,5.24,0,0,0,712.772,492.694Z'
                    transform='translate(-707.533 -472.415)'
                    fill='#311643'
                  />{' '}
                </g>{' '}
                <g
                  id='Group_21'
                  data-name='Group 21'
                  transform='translate(105.369 217.927)'
                >
                  {' '}
                  <path
                    id='Path_67'
                    data-name='Path 67'
                    d='M700.443,504.45a29.958,29.958,0,0,0,19.588-6.725A26.8,26.8,0,0,0,727,489.09a2.4,2.4,0,0,0-4.378-1.981h0c-.273.593-6.916,14.53-26.226,12.294a2.4,2.4,0,0,0-.552,4.774A39.942,39.942,0,0,0,700.443,504.45Z'
                    transform='translate(-693.724 -485.699)'
                    fill='#311643'
                  />{' '}
                </g>{' '}
                <ellipse
                  id='Ellipse_6'
                  data-name='Ellipse 6'
                  cx='25.921'
                  cy='25.921'
                  rx='25.921'
                  ry='25.921'
                  transform='translate(159.517 167.106)'
                  fill='#f49795'
                />{' '}
                <path
                  id='Path_68'
                  data-name='Path 68'
                  d='M708.953,481.344A184.631,184.631,0,0,0,724.7,528.755a25.92,25.92,0,1,0-15.749-47.411Z'
                  transform='translate(-698.894 -309.842)'
                  fill='#f49795'
                />{' '}
                <g
                  id='Group_22'
                  data-name='Group 22'
                  transform='translate(16.696 114.19)'
                >
                  {' '}
                  <ellipse
                    id='Ellipse_7'
                    data-name='Ellipse 7'
                    cx='31.928'
                    cy='31.928'
                    rx='31.928'
                    ry='31.928'
                    transform='translate(102.009 4.806)'
                    fill='none'
                  />{' '}
                  <ellipse
                    id='Ellipse_8'
                    data-name='Ellipse 8'
                    cx='31.928'
                    cy='31.928'
                    rx='31.928'
                    ry='31.928'
                    transform='translate(63.135 73.364) rotate(-170.783)'
                    fill='none'
                  />{' '}
                  <path
                    id='Path_69'
                    data-name='glasses'
                    d='M722.1,467.836a36.786,36.786,0,0,1,36.522,32.8,28.234,28.234,0,0,1,24.164-.018,36.792,36.792,0,1,1-.18,5.308,23.984,23.984,0,0,0-23.8.029,36.73,36.73,0,1,1-36.7-38.119Zm97.2,68.66a31.929,31.929,0,1,0-31.923-31.929A31.962,31.962,0,0,0,819.307,536.5Zm-97.2,0a31.929,31.929,0,1,0-31.93-31.929A31.962,31.962,0,0,0,722.1,536.5Z'
                    transform='translate(-685.372 -467.836)'
                    fill='#351402'
                  />{' '}
                </g>{' '}
                <path
                  id='Path_70'
                  data-name='Path 70'
                  d='M683.478,570.505V526.677c21.487-5.906,45.947-20.366,64.334-34.159Z'
                  transform='translate(-549.444 -234.987)'
                  fill='#f9afab'
                />{' '}
              </g>{' '}
              <path
                id='Path_71'
                data-name='pants'
                d='M640.029,1440.582H740.663l97.61-519.115,71.6-224.992,173.348,744.107h100.634l-93.768-834.806H718.3Z'
                transform='translate(-512.471 309.475)'
                fill='#f36d27'
              />{' '}
              <path
                id='Path_72'
                data-name='shoe_left'
                d='M937.924,756.493v71.116a15.019,15.019,0,0,1-15.017,15.017H721.194a3.475,3.475,0,0,1-3.473-3.472v-13.7a15.6,15.6,0,0,1,8.816-14.048l113.737-54.913Z'
                transform='translate(-717.721 1034.025)'
                fill='#a13c08'
              />{' '}
              <path
                id='Path_73'
                data-name='shoe_right'
                d='M617.555,756.493v71.116a15.019,15.019,0,0,0,15.017,15.017H834.285a3.475,3.475,0,0,0,3.473-3.472v-13.7a15.6,15.6,0,0,0-8.822-14.048L715.206,756.493Z'
                transform='translate(-35.856 1034.025)'
                fill='#a13c08'
              />{' '}
              <path
                id='Path_74'
                data-name='Path 74'
                d='M821.983,749.526l-7.576,40.46H716.345l5.006-40.46Z'
                transform='translate(-593.791 1000.532)'
                fill='#fcbeb7'
              />{' '}
              <path
                id='Path_75'
                data-name='Path 75'
                d='M739.292,749.526l7.959,40.46H649.6l-10.944-40.46Z'
                transform='translate(-67.904 1000.532)'
                fill='#fcbeb7'
              />{' '}
              <path
                id='Path_76'
                data-name='belt'
                d='M1033.3,623.68,655.829,645l3.678-39.229h371.787'
                transform='translate(-453.674 309.475)'
                fill='#a13c08'
              />{' '}
              <path
                id='Path_77'
                data-name='Path 77'
                d='M659.228,538.07'
                transform='translate(-99.336 -16.008)'
                fill='#fcbeb7'
              />{' '}
              <line
                id='Line_1'
                data-name='Line 1'
                x1='85.792'
                y2='65.955'
                transform='translate(579.188 475.337)'
                fill='#fcbeb7'
              />{' '}
              <path
                id='Path_78'
                data-name='shirt'
                d='M868.426,499.509,724.59,561.938a59.1,59.1,0,0,0-25.86,21.6l-57.6,86.785,81.21,71.657,21.7-18.531-24.63,393.186H1091.2l-22.782-256.376,5.052-136.809,19.3,19.228,85.792-65.954-46.1-75.118a114.22,114.22,0,0,0-47.492-43.033l-121.688-59.06S908.1,535.724,868.426,499.509Z'
                transform='translate(-513.574 -201.384)'
                fill='#f6915d'
              />{' '}
              <g
                id='Group_24'
                data-name='Group 24'
                transform='translate(324.288 306.836)'
              >
                {' '}
                <path
                  id='Path_79'
                  data-name='Path 79'
                  d='M678.425,503.843a108.488,108.488,0,0,0,139.406-2.834l3.751,1.818a112.315,112.315,0,0,1-147.117,2.735Z'
                  transform='translate(-674.465 -501.009)'
                  fill='#fff'
                />{' '}
              </g>{' '}
              <path
                id='Path_80'
                data-name='shoulder_right'
                d='M649.134,519.366a31.981,31.981,0,0,0,42.51,30.169,114.25,114.25,0,0,0-41.174-39.322A31.989,31.989,0,0,0,649.134,519.366Z'
                transform='translate(-73.132 -149.921)'
                fill='#ea580c'
              />{' '}
              <path
                id='Path_81'
                data-name='shoulder_left'
                d='M717.216,548.44a31.975,31.975,0,0,0,44.752-38.955l-10.354,4.495a59.1,59.1,0,0,0-25.861,21.6Z'
                transform='translate(-540.598 -153.426)'
                fill='#ea580c'
              />{' '}
              <path
                id='Path_83'
                data-name='Path 83'
                d='M640.037,939.929l37.812-16.875a10.579,10.579,0,0,0,5.627-6.045l5.482-15.093L669.852,906.6l-6.091,13.084a8.983,8.983,0,0,1-11.7,4.46l7.956-24.019a16.409,16.409,0,0,1,8.037-8.758l39.931-19.64,68.869-223.4-52.615-52.353,85.793-65.954,50.164,87.3a43.953,43.953,0,0,1,2.265,39.263L723.239,974.976l-27.631,1.278a9.037,9.037,0,0,1-8.165-4.385l-3.008-5.017-14.542,1.19a9.04,9.04,0,0,1-8-3.63l-3.415-4.611-2.044.534a12.434,12.434,0,0,1-14.142-6.144L632.2,957.554A14.419,14.419,0,0,1,640.037,939.929Z'
                transform='translate(-145.054 -54.688)'
                fill='#fcbeb7'
              />{' '}
              <g
                id='Group_380'
                data-name='Group 380'
                transform='translate(75.369 467.652)'
              >
                {' '}
                <path
                  id='Path_1140'
                  data-name='Path 1140'
                  d='M857.493,939.929l-37.812-16.876a10.576,10.576,0,0,1-5.627-6.046l-5.483-15.093,19.107,4.687,6.092,13.084a8.983,8.983,0,0,0,11.7,4.46l-7.956-24.019a16.41,16.41,0,0,0-8.037-8.758l-39.932-19.639-68.869-223.4,52.615-52.353-85.792-65.954-50.164,87.3a43.956,43.956,0,0,0-2.265,39.264L774.291,974.977l27.631,1.277a9.038,9.038,0,0,0,8.165-4.384l3.008-5.018,14.542,1.19a9.039,9.039,0,0,0,8-3.63l3.414-4.611,2.044.535a12.435,12.435,0,0,0,14.142-6.144l10.093,3.363A14.419,14.419,0,0,0,857.493,939.929Z'
                  transform='translate(-631.488 -530.024)'
                  fill='#fcbeb7'
                />{' '}
              </g>{' '}
              <path
                id='Path_84'
                data-name='Path 84'
                d='M631.488,574.19a32.334,32.334,0,0,0,26.134,31.731l5.441-12.654A43.967,43.967,0,0,0,660.8,554l-6.208-10.8A32.343,32.343,0,0,0,631.488,574.19Z'
                transform='translate(54.348 8.639)'
                fill='#f9afab'
              />{' '}
              <path
                id='Path_85'
                data-name='Path 85'
                d='M66.3,24.723,43.375,4.066a15.794,15.794,0,0,0-20.907-.22L0,23.242a38.232,38.232,0,0,0,66.3,1.481Z'
                transform='translate(70.991 607.469) rotate(-90)'
                fill='#f9afab'
              />{' '}
            </g>{' '}
          </g>{' '}
        </g>{' '}
      </svg>
    </>
  )
}
