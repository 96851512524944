import React, {useState} from 'react'
import CategoryListCollection from './CategoryListCollection'

export default function RefContainerList2({
  headerText,
  listRef,
  editButton,
  insertButton,
  secondaryContentAction,
}) {
  const [editState, setEditState] = useState(false)
  const editingButton = <i className='material-icons'>clear</i>
  const editToggle = (
    <a className='edit-list-icon' onClick={() => setEditState(!editState)}>
      {editState ? editingButton : editButton}
    </a>
  )
  return (
    <>
      <div className='flex justify-between mt-16 text-4xl font-bold'>
        <div className='pl-2'>{editButton}</div>
        <div className='text-center'>{headerText}</div>
        <div className='pr-2'>{insertButton}</div>
      </div>
      <ul className='collection'>
        <CategoryListCollection
          collectionArray={listRef}
          secondaryContent={editState ? 'delete' : 'keyboard_arrow_right'}
          secondaryContentAction={
            editState ? secondaryContentAction : () => console.log('click')
          }
        />
      </ul>
    </>
  )
}
