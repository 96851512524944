import React, {useContext, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import UserContext from '../../context/user/UserContext'
import AuthContext from '../../context/auth/AuthContext'
import {SimpleContainer} from '../layout/Containers'
import {Spinner} from '../layout/Spinner'

const VerifyPage = () => {
  const userContext = useContext(UserContext)
  const authContext = useContext(AuthContext)
  const {isVerified, loading, verifyUser, logout} = authContext
  const {token} = useParams()

  const [_loading, setLoading] = useState(false) // HACK to get loading spinner showing... I don't know why

  useEffect(() => {
    setLoading(true)
    const setVerification = async () => {
      await verifyUser(token)
    }
    setVerification()
    logout()
    setLoading(false)
  }, [])

  return (
    <SimpleContainer>
      {_loading || loading ? (
        <Spinner />
      ) : (
        <div className=''>
          {isVerified ? (
            <div className=''>
              <div className='text-center text-3xl font-bold'>
                User Verified!
              </div>
              <div className='text-center text-xl font-bold'>
                You may now close this window.
              </div>
            </div>
          ) : (
            <div className=''>
              <div className='text-center text-3xl font-bold'>
                Verification Pending
              </div>
              <div className='text-center text-xl font-bold'></div>
            </div>
          )}
        </div>
      )}
    </SimpleContainer>
  )
}

export default VerifyPage
