import React, {useContext, useEffect, useState} from 'react'
import {ListPickPage} from './ListPickPage'
import {Spinner} from '../layout/Spinner'
import {ShopListPage} from './ShopListPage'
import AuthContext from '../../context/auth/AuthContext'
import StoreContext from '../../context/store/StoreContext'
import ListContext from '../../context/list/ListContext'
import {CardContainer, ListContainer} from '../layout/Containers'

export const ShopList = () => {
  const listContext = useContext(ListContext)
  const storeContext = useContext(StoreContext)
  const authContext = useContext(AuthContext)

  const {loadUser} = authContext
  const {currentList, setCurrentList} = listContext
  const {stores, setCurrentStore} = storeContext

  const [showList, setShowList] = useState(false)

  let loading

  useEffect(() => {
    const userLoad = async () => {
      await loadUser()
    }
    userLoad()
    // eslint-disable-next-line
  }, [])

  const toggle = () => {
    setShowList(!showList)
  }

  const shopClick = (list) => {
    loading = true
    setCurrentStore(stores.find((store) => store._id === list.store._id))
    setCurrentList(list)
    toggle()
    loading = false
  }

  return (
    <ListContainer>
      <CardContainer>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {!currentList ? (
              <>
                <ListPickPage shopClick={shopClick} />
              </>
            ) : (
              <>
                <ShopListPage />
              </>
            )}
          </>
        )}
      </CardContainer>
    </ListContainer>
  )
}
