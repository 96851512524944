import React from 'react'
import {CardContainer} from '../layout/Containers'

const CategoryListPage = ({model, headerString, insertButton, collection}) => {
  return (
    <>
      {model && (
        <>
          <div className='h-5 -mt-1 -mx-0.5 z-10 border-t-gray-200 border-x-2 rounded-t-2xl bg-emerald-400'></div>
          <div className='bg-emerald-400 rounded-b-full border-gray-300 shadow-lg shadow-emerald-100 pb-4'>
            <div className='flex items-center justify-center'>
              <div className='flex-grow text-center text-white font-extrabold text-3xl tracking-widest'>
                {headerString}
              </div>
            </div>
          </div>
          <div className='flex justify-end m-5 mb-0 h-10'>{insertButton}</div>
          <div className='flex flex-col gap-4 m-5 rounded-t-xl'>
            {collection}
          </div>
        </>
      )}
    </>
  )
}

export default CategoryListPage
