import React from 'react'
import CategoryListCollection from './CategoryListCollection'

export default function RefContainerList1({headerText, listRef, editButton}) {
  return (
    <>
      <div className='flex justify-between mt-16 text-4xl font-bold'>
        <div className='w-1/3 '>
          <div className=''></div>
        </div>
        <div className='w-1/3 text-center'>{headerText}</div>
        <div className='w-1/3 flex justify-end pr-2'>
          <div className=''>{editButton}</div>
        </div>
      </div>
      <ul className='collection'>
        <CategoryListCollection collectionArray={listRef} />
      </ul>
    </>
  )
}
