import React from 'react'

export const LocationsItems = ({locationName, checkBoxes}) => {
  return (
    <div>
      <div style={{borderBottom: 'solid black 1px'}} className='storeLocName'>
        {locationName}
      </div>
      <ul style={{padding: '10px'}}>{checkBoxes}</ul>
    </div>
  )
}
