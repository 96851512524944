import React from 'react'

export const OkButton = ({action, href}) => {
  return (
    <>
      <a
        href={href}
        onClick={action}
        className='btn-spacer modal-close  btn-flat'
      >
        OK
      </a>
    </>
  )
}

export const CancelButton = ({action, href}) => {
  return (
    <>
      <a
        href={href}
        onClick={action}
        className='btn-spacer modal-close  btn-flat'
      >
        Cancel
      </a>
    </>
  )
}
