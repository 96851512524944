import React, {useEffect, useContext} from 'react'
import AuthContext from '../../context/auth/AuthContext'
import {SimpleContainer} from '../layout/Containers'
import {Spinner} from '../layout/Spinner'

const VerificationSent = () => {
  const authContext = useContext(AuthContext)
  const {sendVerificationEmail, loading} = authContext

  useEffect(() => {
    let token = window.localStorage.getItem('token')
    // TODO add if token doesn't exist / user already verified present different content
    if (!token) {
      console.log('no token')
    }
    sendVerificationEmail(token)
    // eslint-disable-next-line
  }, [])

  return (
    <SimpleContainer>
      {loading ? (
        <Spinner />
      ) : (
        <div className=''>
          <div className='text-center text-3xl'>Verification sent!</div>
          <div className='text-center text-xl'>
            A verification email has been sent to your email address.
          </div>
          <div className='text-center text-xl'>
            Please click the link in the email to verify your account.
          </div>
        </div>
      )}
    </SimpleContainer>
  )
}

export default VerificationSent
