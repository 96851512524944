import React, {useContext, useEffect, useState} from 'react'
import PlaceContext from '../../context/place/PlaceContext'
import CategoryListPage from '../ui/CategoryListPage'
import CategoryListCollection from '../ui/CategoryListCollection'
import {createTextInputs, useFormHandler} from '../../hooks/Custom'
import TextInputModal from '../ui/TextInputModal'
import {HomeIcon, PlusIcon} from '../../img/images'
import ItemListCollection from '../ui/ItemListCollection'
import StoreContext from '../../context/store/StoreContext'

const ItemsList = ({action}) => {
  const placeContext = useContext(PlaceContext)
  let {getPlaces, updatePlace, setCurrent, places, currentPlace} = placeContext

  const [changeVal, submitVal, state] = useFormHandler(updatePlace, {
    name: '',
    homes: [],
  })
  const storeContext = useContext(StoreContext)
  const {getStores} = storeContext

  useEffect(() => {
    getStores()
  }, [])

  const onChange = (e) => {
    e.preventDefault()
    changeVal({[e.target.name]: e.target.value})
  }

  // modal add item listener
  const onClickAddItem = async () => {
    const updatedPlace = {
      ...currentPlace,
      items: [...currentPlace.items, state],
    }
    submitVal(updatedPlace)
    setCurrent({
      data: updatedPlace,
      target: 'currentPlace',
    })
    changeVal({name: ''})
  }

  const getModalContent = () => {
    return createTextInputs({
      changeAction: onChange,
      idStrings: ['name'], // idStrings map to valueObjs for multi inputs
      valueObjs: [state],
      metaOptions: {formName: 'thing'},
    })
  }

  const newItemHeader = (
    <div
      className='center'
      style={{
        lineHeight: '2',
        fontSize: '2em',
        marginTop: '2px',
      }}
    >
      New Item
    </div>
  )

  const homesList = () => {
    currentPlace.homes.map((x) => console.log(x.name))
  }

  const insertTrigger = (
    <PlusIcon color='fill-gray-700' height='10' width='10' />
  )

  return (
    <>
      {currentPlace && (
        <>
          <CategoryListPage
            model={currentPlace.items}
            headerString='Items'
            insertButton={
              <TextInputModal
                modalId='newItemModal1'
                modalContent={getModalContent}
                action={onClickAddItem}
                trigger={insertTrigger}
                triggerClassNames=''
                formName='thing'
                header={newItemHeader}
              />
            }
            collection={
              // <ItemListCollection
              //   collectionArray={currentPlace.items}
              //   secondaryContent={<HomeIcon color='fill-orange-400' />}
              //   action={action}
              // />
              <CategoryListCollection
                collectionArray={currentPlace.items}
                action={action}
              />
            }
          />
        </>
      )}
    </>
  )
}

export default ItemsList
