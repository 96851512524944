import React, {useEffect, useState, useContext} from 'react'
import {StorePickPage} from './StorePickPage'
import {NewListPage} from './NewListPage'
import {Spinner} from '../layout/Spinner'
import StoreContext from '../../context/store/StoreContext'
import {CardContainer, ListContainer} from '../layout/Containers'

export const NewList = () => {
  const [showList, setShowList] = useState(false)

  const storeContext = useContext(StoreContext)

  const {currentStore} = storeContext

  let loading

  useEffect(() => {
    // M.AutoInit()
    // eslint-disable-next-line
  }, [])

  const toggle = () => {
    setShowList(!showList)
  }

  // TODO refactor
  return (
    <ListContainer>
      <CardContainer>
        {!loading ? (
          !currentStore ? (
            <StorePickPage />
          ) : (
            <NewListPage store={currentStore} />
          )
        ) : (
          <Spinner />
        )}
      </CardContainer>
    </ListContainer>
  )
}
