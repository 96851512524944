import React, {useContext} from 'react'
import PlaceContext from '../../context/place/PlaceContext'
import ModalActionAndTrigger from '../ui/ModalActionAndTrigger'

const ItemDetailHeader = (props) => {
  const {item, deleteClick, backButton} = props
  const placeContext = useContext(PlaceContext)

  const {clearCurrent} = placeContext

  const backClick = () => {
    clearCurrent({target: 'currentItem'})
  }

  const trigger = <i className='material-icons'>delete</i>
  const modalContent = 'Deleting cannot be undone.  Continue?'

  const newItemHeader = <span className='center'>New Item</span>

  return (
    <>
      <div className='row'>
        <div className='valign-wrapper detail-header center'>
          <div className='col s2 detail-header-icon'>{backButton}</div>
          <div className='col s8 center'>
            <span className='detail-header-text'>{item.name}</span>
          </div>
          <div className='col s2 detail-header-icon'>
            <ModalActionAndTrigger
              modalId='deleteConfirmModal'
              modalContent={modalContent}
              action={deleteClick}
              trigger={trigger}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ItemDetailHeader
