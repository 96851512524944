import React from 'react'
import {SimpleContainer} from '../layout/Containers'

const TestPage = () => {
  return (
    <SimpleContainer>
      <div className='flex'>
        <div className='flex flex-col mx-2'>
          <div className='w-24 h-24 bg-orange-100 text-center'>100</div>
          <div className='w-24 h-24 bg-orange-200 text-center'>200</div>
          <div className='w-24 h-24 bg-orange-300 text-center'>300</div>
          <div className='w-24 h-24 bg-orange-400 text-center'>400</div>
          <div className='w-24 h-24 bg-orange-500 text-center'>500</div>
          <div className='w-24 h-24 bg-orange-600 text-center'>600</div>
          <div className='w-24 h-24 bg-orange-700 text-center'>700</div>
          <div className='w-24 h-24 bg-orange-800 text-center'>800</div>
          <div className='w-24 h-24 bg-orange-900 text-center'>900</div>
        </div>
        <div className='flex flex-col mx-2'>
          <div className='w-24 h-24 bg-emerald-100 text-center'>100</div>
          <div className='w-24 h-24 bg-emerald-200 text-center'>200</div>
          <div className='w-24 h-24 bg-emerald-300 text-center'>300</div>
          <div className='w-24 h-24 bg-emerald-400 text-center'>400</div>
          <div className='w-24 h-24 bg-emerald-500 text-center'>500</div>
          <div className='w-24 h-24 bg-emerald-600 text-center'>600</div>
          <div className='w-24 h-24 bg-emerald-700 text-center'>700</div>
          <div className='w-24 h-24 bg-emerald-800 text-center'>800</div>
          <div className='w-24 h-24 bg-emerald-900 text-center'>900</div>
        </div>
      </div>
    </SimpleContainer>
  )
}

export default TestPage
