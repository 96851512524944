import React, {useContext, useEffect, useState} from 'react'
import ListContext from '../../context/list/ListContext'
import StoreContext from '../../context/store/StoreContext'
import {ListContainer} from '../layout/Containers'
import {ListChoice} from './ListChoice'
import {Link} from 'react-router-dom'

export const ListPickPage = ({shopClick}) => {
  const listContext = useContext(ListContext)
  const storeContext = useContext(StoreContext)

  const {lists, getLists} = listContext
  const {stores, getStores} = storeContext

  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    getLists()
    getStores()
    setLoading(false)
    // M.AutoInit()

    // eslint-disable-next-line
  }, [])

  // if (lists !== null && !loading && lists.length === 0) {
  //   return (
  //     <div className='container'>
  //       <h4>No lists yet!</h4>
  //     </div>
  //   )
  // }

  const visibilityClick = () => {
    setLoading(true)
    setVisible(!visible)
    console.log('visibility', visible)
    setLoading(false)
  }

  // TODO refactor<?>
  // toggle for showing lists based on number / visible state
  const listStatus =
    lists.length === 0 ? (
      <h4 className='center'>
        No lists yet... <Link to='/newlist'>Create one!</Link>
      </h4>
    ) : lists.map((list) => list.shopped).every((shopped) => shopped) &&
      !visible ? (
      <div className='center'>All lists shopped!</div>
    ) : (
      lists.map((list) => (
        <ListChoice
          key={list._id}
          shopClick={shopClick}
          list={list}
          visible={visible}
          store={stores.find((store) => store._id === list.store._id)}
        />
      ))
    )

  return (
    <>
      {lists !== null && stores !== null && !loading && (
        <>
          <div className='text-center text-3xl p-10 font-bold text-slate-800'>
            Let's get <div className='text-emerald-400'>shoppin'!</div>
            <div className='mt-10 flex flex-col gap-10'>{listStatus}</div>
          </div>
          {/* <ul className=''>
            <li className=''>
              <span className='list-header'>Select list</span>
              <span className='list-header secondary-content'>
                <span
                  onClick={() => visibilityClick()}
                  style={{marginRight: '10px'}}
                >
                  {visible ? (
                    <i className='material-icons'>visibility_off</i>
                  ) : (
                    <i className='material-icons'>visibility</i>
                  )}
                </span>
                Created
              </span>
            </li>
          </ul> */}
        </>
      )}
    </>
  )
}
