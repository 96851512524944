import React, {useEffect} from 'react'
import M from 'materialize-css'
import Modal from '../Modal'
import {OkButton, CancelButton} from '../ui/components/Buttons'

const ModalActionAndTrigger = ({
  modalId,
  modalContent,
  action,
  trigger,
  triggerClassNames,
  additionalClasses,
  header,
}) => {
  useEffect(() => {
    const elem = document.querySelector(`#${modalId}`)
    M.Modal.init(elem)
  }, [])

  return (
    <>
      <a // please pardon the funky syntax for avoiding unneeded whitespace in the className attribute... help is being sought
        className={`modal-trigger${
          typeof triggerClassNames !== 'undefined'
            ? ` ${triggerClassNames}`
            : ``
        }${
          typeof additionalClasses !== 'undefined'
            ? ` ${additionalClasses}`
            : ``
        }`}
        href={`#${modalId}`}
      >
        {/* <i className='material-icons'>sort</i> */}
        {trigger}
      </a>
      <Modal header={header} modalId={modalId} modalContent={modalContent}>
        <CancelButton />
        <OkButton action={action} />
      </Modal>
    </>
  )
}

//<NewHomeForm newHome={newHome} onChange={onChange} />
//newHomeModal1
export default ModalActionAndTrigger
