import React, {useContext} from 'react'
import {useHistory} from 'react-router-dom'
import ListContext from '../../context/list/ListContext'
import StoreContext from '../../context/store/StoreContext'
import {ShoppingListContainer} from './ShoppingListContainer'

export const ShopListPage = () => {
  const storeContext = useContext(StoreContext)
  const listContext = useContext(ListContext)

  const {currentStore, clearCurrentStore} = storeContext
  const {clearCurrentList} = listContext

  const history = useHistory()

  const backClick = () => {
    clearCurrentList()
    clearCurrentStore()
    history.goBack()
  }

  return (
    <>
      {currentStore && (
        <>
          {/* TODO look at pulling out to own component */}
          {/* TODO fix back arrow placement -- not in same place for each page currently */}
          <div className='row valign-wrapper'>
            <div className=' col s1' style={{height: '100%'}}>
              <a onClick={backClick}>
                <i className='material-icons'>arrow_back</i>
              </a>
            </div>
            <div className='col s11'>
              <h3 className='center-align'>{currentStore.name} List</h3>
            </div>
          </div>
          <div className='row'>
            <ShoppingListContainer />
          </div>
        </>
      )}
    </>
  )
}
