import React, {useContext, useEffect, useState} from 'react'
import HomeDetailHeader from './HomeDetailHeader'
import ModalActionAndTrigger from '../ui/ModalActionAndTrigger'
import {CheckBox} from '../ui/FieldNames'
import RefContainerList1 from '../ui/RefContainerList1'
import PlaceContext from '../../context/place/PlaceContext'
import {createCheckBoxes, getDeepCopy} from '../../hooks/Custom'
import { PencilIcon } from '../../img/images'

export const HomeDetail = (props) => {
  const {currentHome} = props

  const placeContext = useContext(PlaceContext)

  const {currentPlace, updatePlace, setCurrent, clearCurrent} = placeContext

  let itemsInThisHome

  const getCurrentHomeItems = (currPlace, currHome) => {
    return currPlace.items
      .filter((item) =>
        item.homes.map((home) => home.name).includes(currHome.name)
      )
      .flat()
  }

  // this stores the changes to-be-saved while the user is editing
  // this is what gets passed on submit
  const [thisHome, setThisHome] = useState({
    ...currentHome,
    name: currentHome.name,
    items: getCurrentHomeItems(currentPlace, currentHome),
  })

  // this is what gets edited from the modal
  // necessary for checkbox management and UI updating
  const [thisHomeShadow, setThisHomeShadow] = useState({
    ...currentHome,
    name: currentHome.name,
    items: getCurrentHomeItems(currentPlace, currentHome), // all stores that current item is in
  })

  const [homeEdits, setHomeEdits] = useState({add: [], remove: []})

  const addToEditsList = (listName, addend) => {
    setHomeEdits((prevState) => ({
      ...prevState,
      [listName]: [...prevState[listName], addend],
    }))
  }

  const removeFromEditsList = (listName, subtrahend) => {
    setHomeEdits((prevState) => ({
      ...prevState,
     }))
  }

  // simple variable assignments holding names from nested objects
  const homeItemNames = thisHome.items.map((item) => item.name)
  const getHomeItemNames = (home) => home.items.map((item) => item.name)

  const onChange = (e) => {
    let items
    const itemName = e.target.name

    const matchingItem = currentPlace.items.find(
      (item) => item.name === itemName
    )

    if (e.target.checked) {
      items = [...thisHomeShadow.items, matchingItem]
      !homeItemNames.includes(matchingItem.name)
        ? addToEditsList('add', matchingItem)
        : removeFromEditsList('remove', matchingItem)
    } else {
      items = thisHomeShadow.items.filter(
        (item) => item.name !== matchingItem.name
      )
      homeItemNames.includes(matchingItem.name)
        ? addToEditsList('remove', matchingItem)
        : removeFromEditsList('add', matchingItem)
    }
    setThisHomeShadow((prevState) => ({...prevState, items}))
  }

  const onSubmit = () => {
    updatePlaceItemHomes({currentPlace, home: thisHome})
    clearCurrent({target: 'currentHome'})
  }

  const updatePlaceItemHomes = () => {
    processItemsToAdd()
    processItemsToRemove()
    updatePlace(currentPlace)
  }

  const processItemsToAdd = () => {
    homeEdits.add.map((updatingItem) => {
      const itemToUpdate = currentPlace.items.find(
        (itm) => itm.name === updatingItem.name
      )
      itemToUpdate.homes = [...itemToUpdate.homes, {name: currentHome.name}]
    })
  }

  const processItemsToRemove = () => {
    homeEdits.remove.map((updatingItem) => {
      const itemToUpdate = currentPlace.items.find(
        (itm) => itm.name === updatingItem.name
      )
      itemToUpdate.homes = itemToUpdate.homes.filter(
        (home) => home.name !== currentHome.name
      )
    })
  }

  const deleteHome = () => {
    const homes = currentPlace.homes.filter(
      (home) => home.name !== currentHome.name
    )
    const items = currentPlace.items.map((item) => {
      item.homes = item.homes.filter((home) => home.name !== currentHome.name)
      return item
    })

    updatePlace({...currentPlace, items, homes})
    setCurrent({data: {...currentPlace, items, homes}, target: 'currentPlace'})
    clearCurrent({target: 'currentHome'})
  }

  // action after clicking OK from the modal
  const onOkModal = () => {
    const {name, items} = getDeepCopy(thisHomeShadow)
    setThisHome((prevState) => ({...prevState, name, items}))
    console.log('this home now set to', [thisHome, name, items])
  }

  
  const itemCheckBoxes = createCheckBoxes(
    currentPlace.items,
    homeItemNames,
    onChange,
    'items',
    ['full-width-checkbox', 'checkbox-buffer']
    )
    
    const editHomesHeader = <span className='center'>{thisHome.name} Items</span>

    const editIcon = <PencilIcon color='fill-gray-600' height='10' width='10' />

  return (
    <>
      <div className='container'>
        <HomeDetailHeader
          home={currentHome}
          deleteClick={deleteHome}
          changeAction={() => console.log('change action')}
        />
        <div className='row'>
          <div className='col s12'>
            <button className='green-button' onClick={() => onSubmit()}>
              <div className='center-text-vertical uppercase'>Save</div>
            </button>
          </div>
        </div>
        <RefContainerList1
          listRef={thisHome.items}
          headerText='Items'
          editButton={
            <ModalActionAndTrigger
              modalId={'editItemsModal'}
              action={onOkModal}
              modalContent={itemCheckBoxes}
              trigger={editIcon}
              header={editHomesHeader}
            />
          }
        />
      </div>
    </>
  )
}

export default HomeDetail
