import React from 'react'
import {HeroMan} from '../../img/images'

const Landing = () => {
  return (
    <>
      <div className='flex flex-col text-slate-800 mx-auto overflow-hidden'>
        <div className='flex my-10 md:my-0 justify-center w-full max-w-7xl px-5 md:pt-20 mx-auto lg:px-0'>
          <h1 className='flex-grow  flex justify-center items-center md:text-left text-6xl md:text-7xl font-extrabold leading-tighter tracking-tighter mb-4 max-w-sm md:max-w-screen-md ml-10 h-full w-full flex-wrap'>
            <div>
              The fast and easy <span className='block'>way to</span>
              <div>
                <span className='text-orange-400'>create</span> +{' '}
                <span className='text-emerald-400'>shop</span>{' '}
                <span className='block'>your grocery list</span>
              </div>
            </div>
          </h1>
          <div className='w-0 md:w-1/2 hidden md:flex'>
            <HeroMan className='h-full flex-shrink' />
          </div>
        </div>
      </div>
      <div className='flex py-10 md:py-20 flex-col bg-gray-100 overflow-hidden border-t-8 border-opacity-60 border-dotted rounded-tl-3xl'>
        <div className='flex max-w-7xl px-5 mx-auto lg:px-0'>
          <div className='flex text-center justify-center flex-wrap text-gray-900 text-3xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-10 max-w-sm md:max-w-screen-md h-full w-full '>
            Getting started is easy...
          </div>
        </div>
        <div className='flex flex-col gap-6 items-center'>
          <div className='flex w-4/5 md:w-2/5 items-center justify-center'>
            <div className='flex justify-center items-center mr-5 text-gray-500 text-4xl w-12 h-12 p-4 border-2 border-gray-300  rounded-full'>
              1
            </div>
            <div className='flex flex-grow text-center h-auto py-5 px-3 items-center justify-center rounded-xl bg-white shadow-md '>
              <div className='text-gray-700 text-2xl'>
                Create your list of{' '}
                <span className='text-orange-400 font-bold'>ITEMS</span>
              </div>
            </div>
          </div>
          <div className='flex w-4/5 md:w-2/5 items-center justify-center'>
            <div className='flex justify-center items-center mr-5 text-gray-500 text-4xl w-12 h-12 p-4 border-2 border-gray-300  rounded-full'>
              2
            </div>
            <div className='flex flex-grow text-center h-auto py-5 px-3 items-center justify-center rounded-xl bg-white shadow-md '>
              <div className='text-gray-700 text-2xl'>
                Give each one a{' '}
                <span className='text-orange-400 font-bold'>HOME</span>
              </div>
            </div>
          </div>
          <div className='flex w-4/5 md:w-2/5 items-center justify-center'>
            <div className='flex justify-center items-center mr-5 text-gray-500 text-4xl w-12 h-12 p-4 border-2 border-gray-300  rounded-full'>
              3
            </div>
            <div className='flex flex-grow text-center h-auto py-5 px-3 items-center justify-center rounded-xl bg-white shadow-md '>
              <div className='text-gray-700 text-xl'>
                Create a list of{' '}
                <span className='text-orange-400 font-bold'>STORES</span> where
                you like to shop
              </div>
            </div>
          </div>
          <div className='flex w-4/5 md:w-2/5 items-center justify-center'>
            <div className='flex justify-center items-center mr-5 text-gray-500 text-4xl w-12 h-12 p-4 border-2 border-gray-300  rounded-full'>
              4
            </div>
            <div className='flex flex-grow text-center h-auto py-5 px-3 items-center justify-center rounded-xl bg-white shadow-md '>
              <div className='text-gray-700 text-center text-xl'>
                Add <span className='text-orange-400 font-bold'>LOCATIONS</span>{' '}
                to each store where you can find your items
              </div>
            </div>
          </div>
          <div className='flex w-4/5 md:w-2/5 items-center justify-center'>
            <div className='flex justify-center items-center mr-5 text-gray-500 text-4xl w-12 h-12 p-4 border-2 border-gray-300  rounded-full'>
              5
            </div>
            <div className='flex flex-grow text-center h-auto py-5 px-3 items-center justify-center rounded-xl bg-white shadow-md '>
              <div className='text-gray-700 text-xl'>
                Make your list and enjoy{' '}
                <span className='text-emerald-400 font-extrabold'>
                  shopping bliss!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Landing
